










import Vue from 'vue';
import Component from 'vue-class-component';
import {SourceLayerInfo} from '../services/SourceInfoService';
import { RenderSourceSlot } from '../modules/rendersources/RenderSource';
import SvgIcon from './guikit/svgIcon.vue';
import { Services } from '@/services/Services';

@Component({
    components: {
        SvgIcon
    },
    props:['source', 'layer', 'slotname']
})
export default class SourceLayerBadge extends Vue{

    clearSlot(){
        console.log(this.$props);
        Services.RenderLayerService.setSourceOnLayer(this.$props.layer, this.$props.slotname, null);
        //this.$props.source.source = undefined;
    }

    get name(){
        if(this.$props.source.source){
            return this.$props.source.source.layer_name;
        }else{
            return "none";
        }
    }

    get variant(){
        if(!this.$props.source || !this.$props.source.source || !this.$props.source.source.layer){
            return "empty";
        }
        switch(this.$props.source.source.layer.layer_type){
            case "ColorTiles": return "image";
            case "ScalarTiles": return "scalar";
            case "ScalarPoints": return "points";
            case "ScalarLines": return "lines";
            default: return "none"
        }
    }
}
