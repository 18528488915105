import { Services } from "@/services/Services";

export class ModelObject{
    public vertexData: WebGLBuffer;
    public normalData: WebGLBuffer;
    public uvData: WebGLBuffer;
    public vertexCount: number;
    public bbox: {x: {min:number, max:number}, y: {min:number, max:number}, z: {min:number, max:number}};

    constructor(vertexData: Float32Array, normalData: Float32Array, uvData?: Float32Array){

        let bbox = {
            x: {min: vertexData[0], max: vertexData[0]},
            y: {min: vertexData[1], max: vertexData[1]},
            z: {min: vertexData[2], max: vertexData[2]},        
        }
        for(var i = 3; i < vertexData.length; i += 3){
            bbox.x.min = Math.min(bbox.x.min, vertexData[i]);
            bbox.x.max = Math.max(bbox.x.max, vertexData[i]);            
            bbox.y.min = Math.min(bbox.y.min, vertexData[i + 1]);
            bbox.y.max = Math.max(bbox.y.max, vertexData[i + 1]);            
            bbox.z.min = Math.min(bbox.z.min, vertexData[i + 2]);
            bbox.z.max = Math.max(bbox.z.max, vertexData[i + 2]);
        }
        this.bbox = bbox;

        let gl: WebGLRenderingContext = Services.GLService.getContext().gl;

        this.vertexCount = vertexData.length / 3;

        this.vertexData = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexData);
        gl.bufferData(gl.ARRAY_BUFFER, vertexData, gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        this.normalData = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.normalData);
        gl.bufferData(gl.ARRAY_BUFFER, normalData, gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        if(this.uvData){
            this.uvData = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, this.uvData);
            gl.bufferData(gl.ARRAY_BUFFER, uvData, gl.STATIC_DRAW);
            gl.bindBuffer(gl.ARRAY_BUFFER, null);
        }
    }

    public dispose(){
        let gl: WebGLRenderingContext = Services.GLService.getContext().gl;
        gl.deleteBuffer(this.vertexData);
        gl.deleteBuffer(this.normalData);
        if(this.uvData){
            gl.deleteBuffer(this.uvData);
        }
    }

    public static load_from_obj(obj: string): ModelObject{
        //only works with triangulated models
        let lines = obj.split("\n");
        let vertices: number[][] = [];
        let normals: number[][] = [];
        let uvs: number[][] = []
        
        let face_vertex_indices: number[][] = [];
        let face_normal_indices: number[][] = [];
        let face_uv_indices: number[][] = [];
        //Read file line by line and collect data
        lines.forEach(line => {
            if(line.startsWith("v ")){
                vertices.push(line.substring(2).split(" ").map(s => parseFloat(s)));
            }else if(line.startsWith("vt")){
                let uv = line.substring(3).split(" ").map(s => parseFloat(s));
                if(uv.length == 1){
                    uv.push(0.5);
                }
                uvs.push(uv);
            }else if(line.startsWith("vn")){
                normals.push(line.substring(3).split(" ").map(s => parseFloat(s)))
            }else if(line.startsWith("f")){
                let face_points = line.substring(2).split(" ");
                let vertex_indices = [];
                let uv_indices = [];
                let normal_indices = [];
                face_points.forEach(p => {
                    let [vertexi, uvi, normali] = p.split("/").map(s => parseInt(s));
                    vertex_indices.push(vertexi);
                    uv_indices.push(uvi);
                    normal_indices.push(normali);
                })
                face_vertex_indices.push(vertex_indices);
                face_normal_indices.push(normal_indices);
                face_uv_indices.push(uv_indices);
            }
        });
        //Create Float32 Arrays
        let vertices_f32 = new Float32Array(face_vertex_indices.length * 3 /*points per face*/ * 3 /*components per point*/);
        let vertex_counter = 0;
        face_vertex_indices.forEach(vis => {
            vis.forEach(v => {
                let vertex = vertices[v - 1];
                vertices_f32[vertex_counter] = vertex[0];
                vertex_counter++;
                vertices_f32[vertex_counter] = vertex[1];
                vertex_counter++;
                vertices_f32[vertex_counter] = vertex[2];
                vertex_counter++;
            });
        });
        
        let normals_f32 = new Float32Array(face_vertex_indices.length * 3 /*points per face*/ * 3 /*components per point*/);
        let normal_counter = 0;
        face_normal_indices.forEach(vis => {
            vis.forEach(v => {
                let vertex = normals[v - 1];
                normals_f32[normal_counter] = vertex[0];
                normal_counter++;
                normals_f32[normal_counter] = vertex[1];
                normal_counter++;
                normals_f32[normal_counter] = vertex[2];
                normal_counter++;
            });
        });

        let uvs_f32 = null;

        if(uvs.length > 0){
            uvs_f32 = new Float32Array(face_uv_indices.length * 3 /*points per face */ * 2 /*uv components per points*/);
            let uv_counter = 0;
            face_uv_indices.forEach(vis => {
                vis.forEach(v => {
                    let vertex = uvs[v - 1];
                    uvs_f32[uv_counter] = vertex[0];
                    uv_counter++;
                    uvs_f32[uv_counter] = vertex[1];
                    uv_counter++;
                })
            })
        }

        return new ModelObject(vertices_f32, normals_f32, uvs_f32);
    }
}