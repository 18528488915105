import { ModelObject } from "@/modules/ModelObject";

export class ModelRepositoryService{
    models: Map<String, ModelObject> = new Map();
    
    public async load_from_url(name: string, url: string){
        let cube_str = await fetch(url).then(r => r.text());
        let model = ModelObject.load_from_obj(cube_str);
        console.log("Loaded model with " + model.vertexCount + " vertices", model);
        this.models.set(name, model);
    }

    public get_model(name: string): ModelObject{
        return this.models.get(name);
    }
}