



















import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
import { GlobalTimeRangeChangedEvent } from '../../services/TimeService';
import { SourceLayerInfo } from '../../services/SourceInfoService';
import { SamplePoint } from '../../services/PointSamplingService';
import { ComputedLineRenderSource } from '@/modules/rendersources/ComputedLineRenderSource';
import { MeasurementChangedEvent } from '@/services/MetaLayerService';
import NumberInput from '../guikit/numberinput.vue'
import { ObjectRenderSource } from '@/modules/rendersources/ObjectRenderSource';
import { Parameter } from '@/modules/Parameter';
@Component({
    props:{
        overlay: Overlay
    },
    components:{
        NumberInput
    }
})
export default class ScaleOverlay extends Vue {
    scaleX: number =  5;
    scaleY: number =  5;
    scaleZ: number =  5;
    private source = null;

    mounted(){
        this.recompute_value();
    }

    recompute_value(){
        this.source = this.$props.overlay.layer.source as ObjectRenderSource;
        let scale_param: Parameter = this.source.parameters['scale'];
        this.scaleX = scale_param.value.x1;
        this.scaleY = scale_param.value.x2;
        this.scaleZ = scale_param.value.x3;
    }

    updateScale(){
        this.source = this.$props.overlay.layer.source as ObjectRenderSource;
        let scale_param = this.source.parameters['scale'];
        scale_param.value.x1 = this.scaleX;
        scale_param.value.x2 = this.scaleY;
        scale_param.value.x3 = this.scaleZ;
    }

}
