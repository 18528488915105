//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { CompositionPipelineFramebuffer } from "../PipelineFramebuffer";
import { Services } from "../../services/Services";
import { Shader } from "../../services/GLService";
import { Vec2, Vec3 } from "../vecmat";

export class Filter {
    name: string;
    human_readable_name: string;
    shader: Shader;
    parameters: {[name:string]: Parameter};
    
    constructor(shader: Shader, parameters: {[name:string]: Parameter} = {}){
        this.name = shader.name;
        this.human_readable_name = shader.name;
        this.shader = shader;
        this.parameters = parameters;
    }

    /*
     * Only put truly universal operations that are shared between ALL filters here.
     */
    preexecute(context: {[name: string]: WebGLRenderingContext | any}): number{
        let gl = context.gl;
        let tex_unit_index = 0;
        gl.useProgram(this.shader.program);
        Object.values(this.parameters).forEach(p => {
            let uloc = this.shader.uniforms[p.shader_name];
            if(!uloc)return;
            switch (p.type) {
                case "boolean": {
                    gl.uniform1i(uloc, p.value);
                    break;
                }
                case "number": {
                    gl.uniform1f(uloc, p.value);
                    break;
                }
                case "colormap": {
                    let cm = Services.ColormapService.getColormapByName(p.value);
                    if(cm){
                        gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
                        gl.bindTexture(gl.TEXTURE_2D, cm)
                        gl.uniform1i(uloc, tex_unit_index);
                        tex_unit_index++;
                    }
                    break;
                }
                case "vector2D": {
                    gl.uniform2f(uloc, p.value.x1, p.value.x2);
                    break;
                }
                case "vector3D": {
                    gl.uniform3f(uloc, p.value.x1, p.value.x2, p.value.x3);
                    break;
                }
                case "texture": {
                    let tex = p.value; //TODO make more resilient
                    if(tex){
                        gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
                        gl.bindTexture(gl.TEXTURE_2D, tex);
                        gl.uniform1i(uloc, tex_unit_index);
                        tex_unit_index++;
                    }
                }
            }
        })
        return tex_unit_index;
    }
}

export class LayerFilter extends Filter {

    execute(context: {[name: string]: WebGLRenderingContext | any}, source: any){ //TODO fix signature
        let tex_unit_index = super.preexecute(context);
        let gl = context.gl;

        gl.depthFunc(gl.ALWAYS);

        if(this.shader.uniforms["value_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.value);
            gl.uniform1i(this.shader.uniforms["value_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["depth_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.depth);
            gl.uniform1i(this.shader.uniforms["depth_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["normal_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.normal);
            gl.uniform1i(this.shader.uniforms["normal_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["worldPosition_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.worldPos);
            gl.uniform1i(this.shader.uniforms["worldPosition_map"], tex_unit_index);
            tex_unit_index++;
        }

        let geometry = Services.GLService.Geometries.quad;
        gl.enableVertexAttribArray(this.shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, geometry.buffer);
        gl.vertexAttribPointer(this.shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);
        gl.drawArrays(gl.TRIANGLE_STRIP, geometry.start, geometry.length);
        gl.disableVertexAttribArray(this.shader.attributes["position"]);
        gl.depthFunc(gl.LESS);
    }
}

export class CompositionFilter extends Filter {

    //TODO: fix signature
    execute(context: {[name: string]: WebGLRenderingContext | any}, layerSource: {value: WebGLTexture, normal: WebGLTexture, depth: WebGLTexture, worldPos: WebGLTexture}, source: CompositionPipelineFramebuffer, target: CompositionPipelineFramebuffer){
        let tex_unit_index = super.preexecute(context);
        let gl = context.gl;

        if(this.shader.uniforms["color_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.color);
            gl.uniform1i(this.shader.uniforms["color_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["depth_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.depth);
            gl.uniform1i(this.shader.uniforms["depth_map"], tex_unit_index);
            tex_unit_index++;
        }
        /*
        if(this.shader.uniforms["normal_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.normal);
            gl.uniform1i(this.shader.uniforms["normal_map"], tex_unit_index);
            tex_unit_index++;
        }
        */
        if(this.shader.uniforms["layer_value_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, layerSource.value);
            gl.uniform1i(this.shader.uniforms["layer_value_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["layer_depth_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, layerSource.depth);
            gl.uniform1i(this.shader.uniforms["layer_depth_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["layer_normal_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, layerSource.normal);
            gl.uniform1i(this.shader.uniforms["layer_normal_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["layer_worldPosition_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, layerSource.worldPos);
            gl.uniform1i(this.shader.uniforms["layer_worldPosition_map"], tex_unit_index);
            tex_unit_index++;
        }

        gl.bindFramebuffer(gl.FRAMEBUFFER, target.framebuffer);
        target.assignDrawBuffers();
        let geometry = Services.GLService.Geometries.quad;
        gl.enableVertexAttribArray(this.shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, geometry.buffer);
        gl.vertexAttribPointer(this.shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);
        gl.drawArrays(gl.TRIANGLE_STRIP, geometry.start, geometry.length);
        gl.disableVertexAttribArray(this.shader.attributes["position"]);
    }

    static Ocean(){
        let r = new CompositionFilter(Services.GLService.Modules.compositing.ocean);
        r.parameters["layer_opacity"] = new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1);
        r.parameters["colormap"] = new Parameter("Gradient", "deep", "colormap", true).setShaderName("gradient");
        r.parameters["optical_density"] = new Parameter("Optical Density", 0.333, "number", true).setShaderName("optical_density").setRange(0,1);
        r.parameters["transmission"] = new Parameter("Transmission", 1.5, "number", true).setShaderName("transmission").setRange(0,2);
        r.parameters["near_ratio"] = new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio");
        return r;
    }

    static Atmosphere(){
        let r = new CompositionFilter(Services.GLService.Modules.compositing.atmosphere);
        r.parameters["layer_opacity"] = new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1);
        r.parameters["colormap"] = new Parameter("Gradient", "deep", "colormap", true).setShaderName("gradient");
        r.parameters["optical_density"] = new Parameter("Optical Density", 0.333, "number", true).setShaderName("optical_density").setRange(0,1);
        r.parameters["transmission"] = new Parameter("Transmission", 1.5, "number", true).setShaderName("transmission").setRange(0,2);
        r.parameters["near_ratio"] = new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio");


        //r.parameters["camera_position"] = new Parameter("camera_position", ).setShaderName("camera_position");
        return r;
    }
}

export class ViewportFilter extends Filter {
    constructor(){
        super(Services.GLService.Modules.viewport.copy, {
            "power": new Parameter("power", 0.5, "number", true).setShaderName("power")
        });
    }

    static dome_top(){
        let r = new ViewportFilter;
        r.shader = Services.GLService.Modules.viewport["dome_top"];
        r.parameters["section_size"] = new Parameter("section_size", new Vec2(0.8, 0.8), "vector2D", false).setShaderName("section_size");
        r.parameters["section_position"] = new Parameter("section_position", new Vec2(0.2, 0), "vector2D", false).setShaderName("section_position");
        r.parameters["section_scale"] = new Parameter("section_scale", new Vec2(1, 1), "vector2D", false).setShaderName("section_scale");
        r.parameters["central_scale"] = new Parameter("central_scale", Math.PI / 2, "number", false).setShaderName("central_scale");
        r.parameters["near_ratio"] = new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio");
        return r;
    }

    static dome_side(direction: Vec2){
        let r = new ViewportFilter;
        r.shader = Services.GLService.Modules.viewport["dome_side"];
        r.parameters["section_size"] = new Parameter("section_size", new Vec2(0.8, 0.8), "vector2D", false).setShaderName("section_size");
        r.parameters["section_position"] = new Parameter("section_position", direction, "vector2D", false).setShaderName("section_position");
        r.parameters["section_scale"] = new Parameter("section_scale", new Vec2(1, 1), "vector2D", false).setShaderName("section_scale");
        r.parameters["central_scale"] = new Parameter("central_scale", Math.PI / 2, "number", false).setShaderName("central_scale");
        r.parameters["azimuth_direction"] = new Parameter("azimuth_direction", direction, "vector2D", false).setShaderName("azimuth_direction");
        r.parameters["overflow_texture"] = new Parameter("overflow_texture", direction.x2 != 0, "boolean", false).setShaderName("overflow_texture");
        r.parameters["near_ratio"] = new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio");
        return r;
    }

    static dyed(color: Vec3){
        let r = new ViewportFilter();
        r.shader = Services.GLService.Modules.viewport["dyed"];
        r.parameters["color"] = new Parameter("color", color, "vector3D", false).setShaderName("dye_color");
        return r;
    }

    static single_projector(frustum: {
        "name": string,
        "fov": {
            "up": number,
            "down": number,
            "left": number,
            "right": number
        },
        "orientation": {
            "yaw": number,
            "pitch": number,
            "roll": number
        }
    }){
        let r = new ViewportFilter();
        r.shader = Services.GLService.Modules.viewport["single_projector"];
        r.parameters["frustum_up_left"] = new Parameter("frustum_up_left", new Vec2(
            -Math.tan(frustum.fov.left / 180 * Math.PI),
            -Math.tan(frustum.fov.up / 180 * Math.PI)
        ), "vector2D", false).setShaderName("frustum_up_left");
        r.parameters["frustum_down_right"] = new Parameter("frustum_down_right", new Vec2(
            Math.tan(frustum.fov.right / 180 * Math.PI),
            Math.tan(frustum.fov.down / 180 * Math.PI)
        ), "vector2D", false).setShaderName("frustum_down_right");
        r.parameters["blending_map"] = new Parameter("blending_map", null, "texture", false, false).setShaderName("blending_map");
        r.parameters["warping_map"] = new Parameter("warping_map", null, "texture", false, false).setShaderName("warping_map");
        r.parameters["gamma_sep"] = new Parameter("gamma_sep", 1.0, "number", false, false).setShaderName("gamma_sep");
        r.parameters["near_ratio"] = new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio");
        return r;
    }

    execute(context: {[name: string]: WebGLRenderingContext | any}, source: CompositionPipelineFramebuffer){
        let gl = context.gl;
        let tex_unit_index = super.preexecute(context);
        if(this.shader.uniforms["clear_color"]){
            let clearcolor = Services.SettingsService.getValueOrDefault("ClearColor", Vec3.empty());
            gl.uniform3f(this.shader.uniforms["clear_color"], clearcolor.x1, clearcolor.x2, clearcolor.x3);
        }
        if(this.shader.uniforms["color_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.color);
            gl.uniform1i(this.shader.uniforms["color_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["depth_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.depth);
            gl.uniform1i(this.shader.uniforms["depth_map"], tex_unit_index);
            tex_unit_index++;
        }
        let geometry = Services.GLService.Geometries.quad;
        gl.enableVertexAttribArray(this.shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, geometry.buffer);
        gl.vertexAttribPointer(this.shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);
        gl.drawArrays(gl.TRIANGLE_STRIP, geometry.start, geometry.length);
        gl.disableVertexAttribArray(this.shader.attributes["position"]);
    }
}