






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import {ColormapService} from '../services/ColormapService';
import { RenderLayer} from '../modules/RenderLayer';
import {SelectedLayerChangedEvent} from '../services/RenderLayerService';
import { Services } from '../services/Services';

@Component
export default class Scale extends Vue{
    selectedLayer: RenderLayer = null;
    boxheight = 1;
    labels= [];
    selectedValue = "";
    selectedIndex = -1;
    ticks: {break: number, label: string}[] = [];

    mounted(){
        this.selectedLayer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", (e: SelectedLayerChangedEvent) => {
            this.selectedLayer = e.selectedLayer;
            if(this.selectedLayer?.ticksHint && Object.keys(this.selectedLayer.ticksHint).length > 0){
                this.ticks = [];
                for(let b of Object.keys(this.selectedLayer.ticksHint)){
                    this.ticks.push({
                        "break": this.break_from_value(parseFloat(b)),
                        "label": this.selectedLayer.ticksHint[b],
                    });
                }
            }else{
                //the old 4 labels
                let breaks = [0.05, (0.9 / 3 + 0.05), (1.8 / 3 + 0.05), 0.95];
                this.ticks = breaks.map(b => ({"break": b, "label": this.range(b)})); 
            }
        });
        setTimeout(() => {
            this.boxheight = document.getElementById('colormap').getBoundingClientRect().height;
        },100);
    }

    break_from_value(v): number{
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            let vmax = filter.parameters["colormap_max"].value;
            let vmin = filter.parameters["colormap_min"].value;
            return 1.0 - ((v - vmin) / (vmax - vmin));
        }else{
            return 0;
        }
    }

    getCurrentColormapCss(): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            return Services.ColormapService.get_CSSGradient(filter.parameters["colormap"].value);
        }else{
            return "black";
        }
    }

    range(pos): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            let vmax = filter.parameters["colormap_max"].value;
            let vmin = filter.parameters["colormap_min"].value;
            let v = (vmax * pos) + (vmin * (1 - pos));
            return FormattingService.num_to_string_unit_si(v, filter.parameters["colormap_max"].unit, 3);
        }
    }

    mousedown(e){
        Services.InteractionService.mousedown("threedee");
    }

}
