







import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
import { GlobalTimeRangeChangedEvent } from '../../services/TimeService';
import { SourceLayerInfo } from '../../services/SourceInfoService';
import { SamplePoint } from '../../services/PointSamplingService';
import { ComputedLineRenderSource } from '@/modules/rendersources/ComputedLineRenderSource';
import { MeasurementChangedEvent } from '@/services/MetaLayerService';
@Component({
    props:{
        overlay: Overlay
    }
})
export default class MeasurementOverlay extends Vue {
    value = "";
    private source = null;
    mounted(){
        Services.MetaLayerService.addEventListener("MeasurementChanged", (e: MeasurementChangedEvent) => {
            if(e.layer = this.$props.overlay.layer){
                this.recompute_value()
            }
        });
    }

    recompute_value(){
        this.source = this.$props.overlay.layer.source as ComputedLineRenderSource;
        this.value = FormattingService.num_to_string_unit_si(this.source.lineLength(), "m", 3);
    }

}
