//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class UIDService {
    private nextUid: number = 0;
    private nextTopicId = {};

    getUid(): number {
        return this.nextUid++;
    }

    getNextTopicId(topic: string){
        if(this.nextTopicId.hasOwnProperty(topic)){
            this.nextTopicId[topic] = this.nextTopicId[topic] + 1;
            return this.nextTopicId[topic];
        }else{
            this.nextTopicId[topic] = 0;
            return 0;
        }
    }

    advanceTopicIdTo(topic: string, min_id: number){
        if(this.nextTopicId.hasOwnProperty(topic)){
            if(this.nextTopicId[topic] < min_id){
                this.nextTopicId[topic] = min_id;
            }
        }else{
            this.nextTopicId[topic] = min_id;
        }
    }
}