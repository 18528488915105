









import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
import { GlobalTimeRangeChangedEvent } from '../../services/TimeService';
import { SourceLayerInfo } from '../../services/SourceInfoService';
import { SamplePoint } from '../../services/PointSamplingService';
import { UEC } from '../../modules/tile';
@Component({
    props:{
        overlay: Overlay
    }
})
export default class TurbidityOverlay extends Vue {
    raw_value: SamplePoint[];
    timeout = null;
    points = "";
    async mounted(){
        await this.sample_value();
        Services.TimeService.addEventListener("GlobalTimeRangeChanged", async (e: GlobalTimeRangeChangedEvent) => {
            if (this.timeout != null){
                clearTimeout(this.timeout);
            } 
            this.timeout = setTimeout(async () => {
                await this.sample_value(); 
                this.timeout = null; 
            }, 500);
        });
    }

    async sample_value(){
        let sli:SourceLayerInfo = this.$props.overlay.sourcelayerinfo;
        let min_height = this.$props.overlay.height - 100;
        let max_height = this.$props.overlay.height + 100;
        let min_pos = new UEC(this.$props.overlay.location.x - 0.0001,this.$props.overlay.location.y - 0.0001);
        let max_pos = new UEC(this.$props.overlay.location.x + 0.0001,this.$props.overlay.location.y + 0.0001);
        let [min_time, max_time] = Services.TimeService.getCurrentTimeRange();
        //this.raw_value = await Services.PointSamplingService.sampleBbox(sli, min_pos, max_pos, min_height, max_height, min_time, max_time);     

        let tdiff = Math.max(max_time - min_time, 1);

        let [min_values, max_values] = sli.layer.datarange;
        



        if(this.raw_value.length == 1){
            let v = this.raw_value[0];
            let y = 1 - (v.value[0] - min_values) / (max_values - min_values);
            this.points =  `0,${y} 1,${y}`;
        }else{
            this.points = this.raw_value.map(v => {
                let x = (v.time - min_time) / tdiff;
                let y = 1- (v.value[0] - min_values) / (max_values - min_values);
                return `${x},${y}`;
            }).join(" ");
        }

        
        
    }

    get value() {
        if(this.raw_value){
            return this.raw_value.map(v => FormattingService.num_to_string_unit_si(v.value[0], this.$props.overlay.unit, 3)).join("   ");
        }else{
            //return FormattingService.num_to_string_unit(this.$props.overlay.information[0], this.$props.overlay.unit, 3);
            return "no data";
        }
    }
}
