














































import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './svgIcon.vue';
@Component({
    props:{
        name: String,
        expanded: {
            type: Boolean,
            default: false
        }
    },
    components:{
        SvgIcon
    }
})
export default class FoldGroup extends Vue {
    private foldedOut: boolean = false;

    toggleFold(){
        this.foldedOut = !this.foldedOut;
    }

    mounted() {
       this.foldedOut = this.$props.expanded;
    }
}

