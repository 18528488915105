function findNearest(array, goal){

    var nearest = array.reduce(function(prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });

    var nearestIdx = array.findIndex(element => element == nearest);
    
    return nearestIdx
    
}

export class DataFrame{
    constructor(cols, data){
        this.data = data;
        this.cols = cols;
        this[Symbol.iterator] = this.rows;
        this.length = this.data.length / this.cols.length;
    }

    * rows(){
        let index = 0;
        while(index < this.data.length){
            yield this.data.slice(index, index + this.cols.length);
            index += this.cols.length;
        }
        return;
    }

    * col(name){
        let index = this.cols.indexOf(name);
        while(index < this.data.length){
            yield this.data[index];
            index += this.cols.length;
        }
        return;
    }
}


export class ScatterPlot{
   
    constructor(target_svg, data, xAxis = "X", yAxis = "Y"){
        this.svg = target_svg;
        this.xLabel = xAxis;
        this.yLabel = yAxis;
        this.xFormatter = (a) => a;
        this.yFormatter = (a) => a;
        console.log("Received data");
        this.data = data;
        //this.overCallback = handleMouseOverXY;
        //this.outCallback = handleMouseOutXY 
    }

    setAxisLabel(axis, label){
        switch(axis){
            case "X":
                this.xLabel = label;
                break;
            case "Y":
                this.yLabel = label;
                break;
        }
    }

    setAxisFormatter(axis, formatter){
        switch(axis){
            case "X":
                this.xFormatter = formatter;
                break;
            case "Y":
                this.yFormatter = formatter;
                break;
        }
    }
   
    static getRequiredInputs(){
        return(["X","Y","Value","Size"]);
    }
    setCallbacks(overCallback, outCallback){
        this.overCallback = overCallback;
        this.outCallback = outCallback;
    }
    
    setColormap(colorStrings){
        this.colormap = colorStrings;
    }

    setZRange(range){
        this.zRange = range;
    }
   
    updateData(data){
        this.data = data;
    }

    draw(){
        console.log("Starting draw");

        //d3 vars
        var pxX = this.svg.getAttribute("width")
        var pxY = this.svg.getAttribute("height")
        //let idx = Array(this.data.rows().length ).fill().map((_, idx) => idx)
        
        var scX = d3.scaleLinear()
                    .domain(d3.extent(this.data.rows(), d=>d[0]))
                    .range([pxX*0.1,pxX*0.9]).nice();
     
        var scY = d3.scaleLinear()
                    .domain(d3.extent(this.data.rows(), d=>d[1]))
                    .range([pxY*0.1,pxY*0.9]).nice(); 

        if(!this.colormap && !this.zRange) {
            var scZ = d3.scaleSequential().domain(d3.extent(this.data.rows(), d=>d[2]))
                    .interpolator(d3.interpolateViridis);
            }
        else if(!this.colormap && this.zRange) {
            var scZ = d3.scaleSequential().domain(this.zRange)
                    .interpolator(d3.interpolateViridis);    
        }
        else if(this.colormap && !this.zRange) {
            var scZ = d3.scaleQuantize().domain(d3.extent(this.data.rows(), d=>d[2]))
                                .range(this.colormap);    
        }
        else{
            var scZ = d3.scaleQuantize().domain(this.zRange)
                                .range(this.colormap);
            }
    
        if(this.data.rows()[3]){
            var scS = d3.scaleLinear()
            .domain(d3.extent(this.data.rows(), d=>d[3]))
            .range([0.1,4]) 
            }

        var svg = d3.select(this.svg);
        
        if(svg.selectAll("g").empty()){
           var g = svg.append("g");
        }else{
            g = svg.select("g");
        }
    
        //circles
        g.selectAll("circle").data(this.data.rows()).exit().remove();
    
        g.selectAll("circle").data(this.data.rows()).enter()
            .append("circle")
            .attr("r", d=>d[3]?scS(d[3]):2)
            .attr("data-interactive", d=>d[2]?d[2]:"none")
            .attr("fill-opacity", "0.5");
    
        g.selectAll("circle").data(this.data.rows())
            .transition()
            .ease(d3.easeCubic)
            .duration(500)
            .attr("fill",d=>d[2]?scZ(d[2]):"b")
            .attr("cx", d=> d[0]?scX(d[0]):0)
            .attr("cy", d => d[1]?scY(d[1]):0);
    
        //axes
        d3.select(".y.axis").remove();
        d3.select(".y.axis").remove();
    
        var axYMaker = d3.axisLeft(scY);
        var axXMaker = d3.axisBottom(scX)
    
        if(svg.select(".y.axis").empty()){
            svg.append("g").attr("class", "y axis")
            .attr("id","axY").call(axYMaker)
            .attr("transform", "translate("+pxX*0.1+",0)");
        }else{
    
            svg.select(".y.axis")
            .transition()
            .duration(300)
            .call(axYMaker);
    
        }
        
        if(svg.select(".x.axis").empty()){
            svg.append("g").attr("class", "x axis")
            .attr("id","axX").call(axXMaker)
            .attr("transform", "translate(0,"+pxY*0.90+")")
        }else{
    
            svg.select(".x.axis")
            .transition()
            .duration(300)
            .call(axXMaker);
    
        }
    
        svg.append("text")             
        .attr("transform",
            "translate(" + (pxX/2) + " ," + 
                           (pxY - pxY*0.03) + ")")
        .style("text-anchor", "middle")
        .text(this.xLabel);
    
        svg.append("text")             
        .attr("transform",
            "translate(" + (pxX*0.05) + " ," + 
                           (pxY*0.5) + ")" +
            "rotate(" + 270 + ")")
        .style("text-anchor", "middle")
        .text(this.yLabel);
        
    }

}

export class XYPlot{

    constructor(target_svg, data, xAxis = "X", yAxis = "Y"){
        this.xFormatter = (a) => a;
        this.yFormatter = (a) => a;
        this.svg = target_svg;
        this.xLabel = xAxis;
        this.yLabel = yAxis;
        this.data = data;
    }

    setAxisLabel(axis, label){
        switch(axis){
            case "X":
                this.xLabel = label;
                break;
            case "Y":
                this.yLabel = label;
                break;
        }
    }

    setAxisFormatter(axis, formatter){
        switch(axis){
            case "X":
                this.xFormatter = formatter;
                break;
            case "Y":
                this.yFormatter = formatter;
                break;
        }
    }
   
    static getRequiredInputs(){

        return(["X","Y"]);

    }

    setColormap(colorStrings){
        this.colormap = colorStrings;
    }

    setZRange(range){
        this.zRange = range;
    }
   
    updateData(data){
        this.data = data;
    }

    draw(){//d3 vars
    var pxX = this.svg.getAttribute("width")
    var pxY = this.svg.getAttribute("height")

    var scX = d3.scaleLinear()
                .domain(d3.extent(this.data.rows(), d=>d[0]))
                .range([pxX*0.1,pxX*0.9]).nice(); 

    var scY = d3.scaleLinear()
                .domain(d3.extent(this.data.rows(), d=>d[1]))
                .range([pxY*0.9,pxY*0.1]).nice() 

    var scZ = d3.scaleSequential().domain(d3.extent(this.data.rows(), d=>d[2]))
                .interpolator(d3.interpolateViridis);

    var svg = d3.select(this.svg);
    
    if(svg.selectAll("g").empty()){
       var g = svg.append("g");
    }else{
        g = svg.select("g");
    }

     //circles
     g.selectAll("circle").remove();
            
     g.selectAll("circle").data(this.data.rows()).enter()
         .append("circle")
         .attr("data-interactive", d=>[d[0],d[1]])
         .attr("r", "5")
         .attr("fill", "red")
         .attr("fill-opacity", "0")
         .attr("cx", d=> d[0]?scX(d[0]):0)
         .attr("cy", d => d[1]?scY(d[1]):0);

     //lines
     var lineMkr = d3.line().curve(d3.curveStep)
                 .x(d=> d[0]?scX(d[0]):0)
                 .y(d => d[1]?scY(d[1]):0)
            
            g.selectAll("path").remove();
            g.append("path").attr("fill", "none")
                            .attr("stroke", "blue")
                            .attr("d", lineMkr(this.data.rows()));
    
    d3.select(".y.axis").remove();
    d3.select(".x.axis").remove();

    var axYMaker = d3.axisLeft(scY);
    var axXMaker = d3.axisBottom(scX)

    axYMaker.tickFormat(d => this.yFormatter(d)) 
    axXMaker.tickFormat(d => this.xFormatter(d))

    svg.append("g").attr("class", "y axis")
    .attr("id","axY").call(axYMaker)
    .attr("transform", "translate("+pxX*0.1+",0)");   

    svg.append("g").attr("class", "x axis")
    .attr("id","axX").call(axXMaker)
    .attr("transform", "translate(0,"+pxY*0.90+")")

    svg.append("text")             
      .attr("transform",
            "translate(" + (pxX/2) + " ," + 
                           (pxY - pxY*0.025) + ")")
      .style("text-anchor", "middle")
      .text(this.xLabel);
    
    svg.append("text")             
      .attr("transform",
            "translate(" + (pxX*0.07) + " ," + 
                           (pxY*0.5) + ")" +
            "rotate(" + 270 + ")")
      .style("text-anchor", "middle")
      .text(this.yLabel);
}

}

export class FeatherPlot{

    constructor(target_svg, data){
        this.svg = target_svg;
        this.data = data;
    }
   
    static getRequiredInputs(){

        return(["Direction","Magnitude","Index","Backscatter"]);

    }

    updateData(data){
        this.data = data;
    }

    setAxisLabel(axis, label){
        return;
    }

    setAxisFormatter(axis, formatter){
        return;
    }

    draw(){ 
    //d3 vars
    var arrow = d3.select("body").append("svg").append("line")
                .attr("x1" , "0").attr("x2" , "0")
                .attr("y1" , "0").attr("y2" , "1")
                .attr("id" , "arrow");

    var pxX = this.svg.getAttribute("width")
    var pxY = this.svg.getAttribute("height")

    var scIdx = d3.scaleLinear()
                .domain(d3.extent(this.data.rows(), d=>d[2]))
                .range([pxX*0.1,pxX*0.9]).nice() 
    var scMags = d3.scaleLinear()
                    .domain([0,d3.max(this.data.rows(), d=>d[1])])
                    .range([0,pxY*0.4]) // scale from pxY*0.1 to pxY*0.9/2
    var scAxY = d3.scaleLinear()
                .domain([-d3.max(this.data.rows(), d=>d[1]),d3.max(this.data.rows(), d=>d[1])])
                .range([pxY*0.9,pxY*0.1])
    
    var scBS = d3.scaleLinear()
        .domain(d3.extent(this.data.rows(), d=>d[3]))
        .range([0,1]);

    var svg = d3.select(this.svg);
    
    if(svg.selectAll("g").empty()){
        var g = svg.append("g");
    }else{
        g = svg.select("g");
    }

    if(d3.select("#d3-tooltip")[0]==null){
        var div = d3.select("body").append("div")
         .attr("id", "d3-tooltip")
         .style("opacity", 0);    
    }
    
    //arrows
    g.selectAll("use").data(this.data.rows())
                    .exit().remove();

    g.selectAll("use").data(this.data.rows()).enter()
        .append("use")
        .attr("data-interactive", d=>[d[0],d[1]])
        .attr("href", "#arrow")
        .attr("stroke", "blue")
        .attr("opacity", d=>d[3]?scBS(d[3]):0.5)
        .attr("stroke-width", "1")
    
    g.selectAll("use").data(this.data.rows())
        .transition()
        .ease(d3.easeCubic)
        .duration(200)
        .attr("transform", 
              d=>"translate("+scIdx(d[2])+","+pxY*0.5+") "+
                "rotate("+d[0]+","+0+","+0+") "+
                "scale(1,-"+scMags(d[1])+")")
        
    g.append("use")
        .attr("href", "#arrow")
        .attr("stroke", "red")
        .attr("stroke-width", "1")
    .attr("transform", 
              "translate("+scIdx(0)+","+pxY*0.5+") "+
                "rotate(45,"+0+","+0+") "+
                "scale(1,-"+scMags(d3.mean(this.data.rows(), d=>d[1]))+")")
    
    //axes
    d3.select(".y.axis").remove();
    d3.select(".y.axis").remove();

    var axYMaker = d3.axisLeft(scAxY);
    var axXMaker = d3.axisBottom(scIdx)

    if(svg.select(".y.axis").empty()){
        svg.append("g").attr("class", "y axis")
        .attr("id","axY").call(axYMaker)
        .attr("transform", "translate("+pxX*0.1+",0)");
    }else{

        svg.select(".y.axis")
        .transition()
        .duration(300)
        .call(axYMaker);

    }
    
    if(svg.select(".x.axis").empty()){
        svg.append("g").attr("class", "x axis")
        .attr("id","axX").call(axXMaker)
        .attr("transform", "translate(0,"+pxY*0.5+")")
    }else{

        svg.select(".x.axis")
        .transition()
        .duration(300)
        .call(axXMaker);

    }
    //y label
    svg.append("text")             
        .attr("transform",
            "translate(" + (pxX*0.06) + " ," + 
                           (pxY*0.5) + ")" +
            "rotate(" + 270 + ")")
        .style("text-anchor", "middle")
        .text("magnitude [mm/s]");
}
    
}

export class RosePlot{

    constructor(target_svg, data){
        this.svg = target_svg;
        this.data = data;
    }
   
    static getRequiredInputs(){

        return(["Direction","Magnitude"]);

    }

    setAxisLabel(axis, label){
        return;
    }

    setAxisFormatter(axis, formatter){
        return;
    }

    updateData(data){
        this.data = data;
    }

    draw(){
      //d3 vars
      var pxX = this.svg.getAttribute("width");
      var pxY = this.svg.getAttribute("height");
      var pxMin = d3.min([pxX, pxY]);
  
      var numRings = 4;
      var numBins = 12;
      var numMags= 20;
      var angleWidth = 360/numBins;
      var angleThresholds = d3.range(0,360,angleWidth);
      var magThresholds = d3.range(d3.min(this.data.rows(),d=>d[1]),d3.max(this.data.rows(),d=>d[1]),
                  (d3.max(this.data.rows(),d=>d[1])-d3.min(this.data.rows(),d=>d[1]))/numMags);
      
      //histograms
      var aHist = d3.histogram();
      aHist.thresholds(angleThresholds)
      aHist.value(d=>d[0])
      var angleHist = aHist(this.data.rows());
      var dLen = this.data.length;
      var maxPct = d3.max(angleHist,d=>d.length)/dLen;
      
      var rHist = d3.histogram();
      //rHist.domain(d3.extent(data, d=>d[1]))
      rHist.thresholds(magThresholds)
      rHist.value(d=>d[1])
  
      //scales
      var scPct = d3.scaleLinear().domain([0,maxPct])
          .range([pxMin*0.05,pxMin*0.9/2]); //.nice();
      var scR = d3.scaleLinear().domain([0,numRings])
          .range([pxMin*0.05,pxMin*0.9/2]).nice();
      
    //   switch(arguments.length) {
    //       case 2: var scZ = d3.scaleSequential().domain(d3.extent(this.data.rows(), d=>d[1]))
    //                           .interpolator(d3.interpolateViridis);
    //               break;
    //       case 3: var scZ = d3.scaleQuantize().domain(d3.extent(this.data.rows(), d=>d[2]))
    //                           .range(colormap);
    //               break;
    //       case 4: var scZ = d3.scaleQuantize().domain(d3.extent(colorRange))
    //                           .range(colormap);
    //               break;
    //   } // no colormaps right now...
      
    var scZ = d3.scaleSequential().domain(d3.extent(this.data.rows(), d=>d[1]))
                               .interpolator(d3.interpolateViridis);


    var scBS = d3.scaleLinear()
        .domain(d3.extent(this.data.rows(), d=>d[2]))
        .range([0,1]);    
    //drawing
    //petals
    var svg = d3.select(this.svg)
    
    if(svg.selectAll("g").empty()){
        var g = svg.append("g");
    }else{
        g = svg.select("g");
    }
    
    var dataRose =  [];
    
    //make Rose Data 
    angleHist.forEach(function(angleBin){
                        
        var angleBegin = angleThresholds[findNearest(angleThresholds,angleBin.x0)]; 
        var angleEnd = angleBegin + angleWidth;
        
        var radidusHist = rHist(angleBin);
        var radiusBegin = 0;
        var radiusEnd = 0;
        radidusHist.forEach(function(rBin){
    
            if(rBin.length>0){
                radiusEnd = radiusBegin + rBin.length;
                let magValue = d3.mean(rBin.map(val=>val[1])) 
                
                let petal = {angleBegin:angleBegin,
                            angleEnd:angleEnd,
                            radiusBegin:radiusBegin,
                            radiusEnd:radiusEnd,
                            magValue:magValue};
                
                radiusBegin = radiusEnd;
                
                dataRose = dataRose.concat(petal)
            }                });
        
    });
        
    //rose petals
    g.selectAll("path").data(dataRose).exit().remove();
    
    g.selectAll("path").data(dataRose)
        .enter().append("path")
        .attr("transform", 
        "translate("+ pxX/2+ ","+pxY/2+")")

    g.selectAll("path").data(dataRose)
        .transition()
        .ease(d3.easeCubic)
        .duration(300)
        .attr("fill", d => scZ(d.magValue))
        .attr("d", d3.arc()
            .innerRadius(d => scPct(d.radiusBegin/dLen))
            .outerRadius(d => scPct(d.radiusEnd/dLen))
//                        .outerRadius(d => scPct(d))
            .startAngle(d => d.angleBegin*Math.PI/180)
            .endAngle(d => d.angleEnd*Math.PI/180)
            //.padAngle(0.0)
            //.padRadius(innerRadius));
            );
    
    //grid
    if(d3.select("#grid").empty()){
        var grid = svg.append("g").attr("id", "grid")
            //rings
        
        grid.selectAll("circle").data(d3.range(0,numRings+1)).enter()
            .append("circle")
            .attr("r", d=>scR(d))
            .attr("stroke", "black")
            .attr("fill", "none")
            .attr("cx", pxX/2)
            .attr("cy", pxY/2);
        
            //angle bins
        grid.selectAll("line").data(angleThresholds).enter()
            .append("line")
            .attr("x1", pxX/2)
            .attr("x2", pxX/2)
            .attr("y1", -scR(0)+pxY/2)
            .attr("y2", -scR(numRings)+pxY/2)
            .attr("stroke", "black")
            .attr("fill", "none")
            .attr("transform", 
            d=>"rotate("+d+","+pxX/2+","+pxY/2+") ");
          
              //opacity for axis
            grid.append("path")
              .attr("transform", 
                  "translate("+ pxX/2+ ","+pxY/2+")")
              .attr("fill", "white")
              .attr("opacity","0.7")
              .attr("d", d3.arc()
                  .innerRadius(d => scPct(0))
                  .outerRadius(d => scPct(maxPct*1.1))
                  .startAngle(30*Math.PI/180)
                  .endAngle(60*Math.PI/180)
                  );

            //Cardinal Points
            grid.append("text")             
            .attr("transform",
                "translate(" + (pxX*0.5) + " ," + (pxY*0.045) + ")")
            .style("text-anchor", "middle")
            .text("N");
            grid.append("text")             
                .attr("transform",
                    "translate(" + (pxX*0.48 + pxMin/2) + " ," + (pxY*0.5) + ")")
                .style("text-anchor", "middle")
                .text("E");
            grid.append("text")             
                .attr("transform",
                    "translate(" + (pxX*0.5) + " ," + (pxY*0.98) + ")")
                .style("text-anchor", "middle")
                .text("S");
            grid.append("text")             
                .attr("transform",
                    "translate(" + (pxX*0.51 - pxMin/2) + " ," + (pxY*0.5) + ")")
                .style("text-anchor", "middle")
                .text("W"); 
      }
      var axXMaker = d3.axisBottom(scPct)
                  .ticks(4,".0%");
          
      if(svg.select(".x.axis").empty()){
          svg.append("g").attr("class", "x axis")
          .attr("id","axX").call(axXMaker)
          .attr("transform","translate("+ pxX*0.5 +","+pxY*0.5+") rotate(-60)");    
      }else{
  
          svg.select(".x.axis")
          .transition()
          .duration(300)
          .call(axXMaker);
      }
       
    }
}
