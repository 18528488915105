//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Services } from "@/services/Services";
import { SettingsChangedEvent } from "@/services/SettingsService";
import { Vec2, Vec3 } from "./vecmat";

export type SerializedParameter = {
    name: string,
    shader_name: string,
    value: any,
    default_value: any,
    type: "number" | "string" | "boolean" | "colormap" | "select" | "vector2D" | "vector3D" | "texture",
    range: number[],
    step: number,
    options: any[],
    unit: string,
    userVisible: boolean,
}

export class Parameter {
    public name: string = ""; //The user-visible name
    public shader_name: string; //The name used in shaders
    public value_internal: any;
    public get value() : any {
            return this.value_internal;
    }
    public set value(value : any) {
        this.value_internal = value;
        Services.SettingsService.dispatchEvent(new SettingsChangedEvent(this));
    }
    public default_value: any;
    public type: "number" | "string" | "boolean" | "colormap" | "select" | "vector2D" | "vector3D" | "texture";
    public range: number[];
    public step: number = null;
    public options: any[];
    public unit: string;
    public userVisible: boolean = true;
    public Actions: ParameterAction[] = [];

    constructor(name: string, default_value: any, type: "number" | "string" | "boolean" | "colormap" | "select" | "vector2D" | "vector3D" | "texture" = "number", userVisible: boolean = true, sync: boolean = false) {
        this.name = name;
        this.default_value = default_value;
        this.value_internal = default_value;
        this.type = type;
        this.range = null;
        this.unit = null;
        this.options = [];
        this.userVisible = userVisible;
    }

    setValue(value: any, remote=false){
        switch(this.type){
            case "vector2D":
                this.value_internal = new Vec2(value.x1, value.x2);
                break;
            case "vector3D":
                this.value_internal = new Vec3(value.x1, value.x2, value.x3);
                break;
            case "boolean":
                this.value_internal = !!value;
                break;
            default:
                this.value_internal = value;
        }
        this.value_internal = value;
        Services.SettingsService.dispatchEvent(new SettingsChangedEvent(this, remote));
    }        


    setRange(min: number, max: number) {
        this.range = [min, max];
        return this;
    }

    setStep(step: number){
        this.step = step;
        return this;
    }

    setUnit(unit: string) {
        this.unit = unit;
        return this;
    }

    setShaderName(name: string) {
        this.shader_name = name;
        return this;
    }

    addOption(option: any){
        this.options.push(option);
        return this;
    }

    registerAction(Name: string, Action: (() => void)){
        this.Actions.push(new ParameterAction(Name, Action));
    }

    executeAction(name: string){
        let action = this.Actions.find(a => a.Name == name);
        if(action){
            action.Action();
        }
    }

    ///Does NOT serialize the actions!
    serialize(): SerializedParameter{
            return ({
                name: this.name,
                shader_name: this.shader_name,
                value: this.value_internal,
                default_value: this.default_value,
                type: this.type,
                range: this.range,
                step: this.step,
                options: this.options,
                unit: this.unit,
                userVisible: this.userVisible,
            });
    }

    static deserialize(serialized_object: SerializedParameter): Parameter{
        let default_value = serialized_object.default_value;
        let value = serialized_object.value;
        if(serialized_object.type == "vector3D"){
            default_value = new Vec3(default_value.x1, default_value.x2, default_value.x3);
            value = new Vec3(value.x1, value.x2, value.x3);
        }else if(serialized_object.type == "vector2D"){
            default_value = new Vec2(default_value.x1, default_value.x2);
            value = new Vec2(value.x1, value.x2);
        }
        let param = new Parameter(serialized_object.name, serialized_object.default_value, serialized_object.type);
        param.value_internal = value;
        param.range = serialized_object.range;
        param.step = serialized_object.step;
        param.options = serialized_object.options;
        param.unit = serialized_object.unit;
        param.userVisible = serialized_object.userVisible;
        param.shader_name = serialized_object.shader_name;
        return param;
    }


}

export class ParameterAction{
    public Name: string;
    public Action: (() => any);

    constructor(Name: string, Action: (() => any)){
        this.Name = Name;
        this.Action = Action;
    }

}