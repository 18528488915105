//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import {Services} from '../services/Services';
import {SourceLayerInfo} from '../services/SourceInfoService';
import { LayerFilter } from './renderfilters/RenderFilter';
import { RenderSource } from './rendersources/RenderSource';
import { TileRenderSource } from "./rendersources/TileRenderSource";
import { CompositionFilter } from './renderfilters/RenderFilter';
import { ColormapFilter } from './renderfilters/ColormapFilter';
import { HillShadingFilter } from './renderfilters/HillShadingFilter';
import { PointRenderSource } from './rendersources/PointRenderSource';
import { VectorPointRenderSource } from './rendersources/VectorPointRenderSource';
import { VectorTracerRenderSource } from './rendersources/VectorTracerSource';
import { UECArea } from './tile';
import { Parameter } from './Parameter';
import { VectorPointRenderSourceHack } from './rendersources/VectorPointRenderSourceHack';
import { PointRenderSourceHack } from './rendersources/PointRenderSourceHack';
import { Mat4, Vec2, Vec3 } from './vecmat';
import { LineRenderSource } from './rendersources/LineRenderSource';
import { SerializedOverlay } from '@/services/OverlayService';
import { VectorTracerArrowRenderSource } from './rendersources/VectorTracerArrowSource';
import { PieChartPointRenderSource } from './rendersources/PieChartPointRenderSource';
import { SphereRenderSource } from './rendersources/SphereRenderSource';
import { QuadRenderSource } from './rendersources/QuadRenderSource';

export type SerializedRenderLayer = {
    id: number,
    slots: {"slot": string, "path": string}[],
    overlays: SerializedOverlay[],
    source_params: {"parameter": string, "value": any}[],
    filter_pipeline_params: {"filter": string, "parameter": string, "value": any}[],
    composition_parameters: {"parameter": string, "value": any}[],
    info: {
        "visible": boolean,
        "name": string,
        "typeHint": string
    };
}

export class RenderLayer{
    source: RenderSource;
    filterPipeline: LayerFilter[] = [];
    visible: boolean;
    name: string;
    id: number;
    changeId: number;
    compositionFilter: CompositionFilter;
    overlayHint: string;
    ticksHint: {[key: number]: string} = {};
    unit: string;
    uid: string;
    typeHint: string;

    getSourceInfos(): SourceLayerInfo[]{
        return this.source.getSourceInfos();
    }

    getVerticalBoundsWorldspace(): [number, number] {
        if(this.source)return this.source.getVerticalBoundsWorldSpace();
        return [1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.source)return this.source.getVerticalBoundsNative();
        return [1, 1];
    }

    getExtent(): UECArea {
        if(this.source){
            let extent = this.source.getExtent();
            return extent;
        }
        return null;
    }

    getTimeRange(): [number, number] {
        if(this.source)return this.source.getTimeRange();
        return null;
    }

    constructor(){
        this.uid = "RenderLayer" + Services.UIDService.getUid();
    }

    serialize(): SerializedRenderLayer{
        let serialized: any = {}; //TODO better type signature
        //0. Store id
        serialized.id = this.id;
        //1. Store sources
        serialized.slots = [];
        for(var s in this.source.slots){
            if(this.source.slots[s]){
                if(this.source.slots[s].source){
                    serialized.slots.push({
                        "slot": s,
                        "path": this.source.slots[s].source.getPath()
                    });
                }else{
                    serialized.slots.push({
                        "slot": s,
                        "path": null
                    });
                }
                
            }
        }
        serialized.overlays = [];
        for(let  o of Services.OverlayService.overlays){
            if(o.layer == this){
                serialized.overlays.push(o.serialize());
            }
        }

        //2. Store source parameters
        serialized.source_params = [];
        for(var p in this.source.parameters){
            serialized.source_params.push({
                "parameter": p,
                "value": this.source.parameters[p].value
            })
        }
        //TODO maybe serialize the pipeline here?
        //3. store filter pipeline paramters
        serialized.filter_pipeline_params = [];
        for(var f in this.filterPipeline){
            for(var p in this.filterPipeline[f].parameters){
                serialized.filter_pipeline_params.push({
                    "filter": f,
                    "parameter": p,
                    "value": this.filterPipeline[f].parameters[p].value
                });
            }
        }
        //4. store composition filter paramters
        serialized.composition_parameters = [];
        for(var p in this.compositionFilter.parameters){
            serialized.composition_parameters.push({
                "parameter": p,
                "value": this.compositionFilter.parameters[p].value
            });
        }
        //5. store additional information
        serialized.info = {
            "visible": this.visible,
            "name": this.name,
            "typeHint": this.typeHint
        };
        //6. return serialized result
        return serialized;
    }

}

export class RenderLayerFactory{
    static fromSerializedObject(serialized: SerializedRenderLayer): RenderLayer{
        //1. Create layer
        let layer: RenderLayer;
        switch(serialized.info.typeHint){
            case "ColormapScalar": 
                layer = RenderLayerFactory.createColormapScalarLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "ColormapDifference":
                layer = RenderLayerFactory.createColormapDifferenceLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data1")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Image":
                layer = RenderLayerFactory.createImageLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Points":
                layer = RenderLayerFactory.createPointsLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path),
                );
                break;
            case "PointsHACK":
                layer = RenderLayerFactory.createPointsLayerHACK(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path)
                );
                break;
            case "Vec2Points":
                layer = RenderLayerFactory.createVec2PointsLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path)
                );
                break;
            case "Vec2PointsHack":
                layer = RenderLayerFactory.createVec2PointsLayerHACK(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path)
                );
                break;
            case "Vector2":
                layer = RenderLayerFactory.createVector2Layer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datau")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datav")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path)
                );
                break;
            case "Vector2Arrow":
                    layer = RenderLayerFactory.createVector2ArrowLayer(
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datau")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datav")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path)
                    );
                    break;
            case "Lines":
                layer = RenderLayerFactory.createLinesLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "lines")?.path)
                )
                break;
            case "Ocean":
                layer = RenderLayerFactory.createOceanLayer();
                break;
            case "Atmosphere":
                layer = RenderLayerFactory.createAtmosphereLayer();
                break;
            case "PieChartPoints":
                let extra_slis = [];
                console.log(serialized);
                for(let i = 2; i <= 8; i++){
                    let source_info = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute" + i)?.path);
                    if(source_info){
                        extra_slis.push(source_info);
                    }
                }
                let attribute1 = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute1")?.path);
                if(!attribute1){
                    if(extra_slis.length > 0){
                        attribute1 = extra_slis.pop();
                    }
                }
                console.log("Extra slis: ", extra_slis);
                layer = RenderLayerFactory.createPieChartPointsLayer(
                    attribute1,
                    extra_slis
                );
                break;
            default:
                throw "No layer typeHint registered for " + serialized.info.typeHint;
        }
        //2. Set source params
        for(let p of serialized.source_params){
            layer.source.parameters[p.parameter].value = p.value;
        }
        //3. Set filter params
        for(let p of serialized.filter_pipeline_params){
            //Hydrate value if necessary
            let value = p.value;
            let typeClass = layer.filterPipeline[parseInt(p.filter)].parameters[p.parameter].type;
            if(typeClass == "vector3D"){
                value = new Vec3(value.x1, value.x2, value.x3);
            }else if (typeClass == "vector2D"){
                value = new Vec2(value.x1, value.x2);
            }
            layer.filterPipeline[parseInt(p.filter)].parameters[p.parameter].value = value;
        }
        //4. Set composition params
        for(let p of serialized.composition_parameters){
            layer.compositionFilter.parameters[p.parameter].value = p.value;
        }
        //5. Set additional info
        layer.name = serialized.info.name;
        layer.visible = serialized.info.visible;
        layer.id = serialized.id;
        return layer;
    }

    static createColormapScalarLayer(parameter: SourceLayerInfo, displacement: SourceLayerInfo = null): RenderLayer{
        let new_source = new TileRenderSource();
        new_source.name = parameter.instance_name + "(" + parameter.layer_name + ")";
        new_source.slots["displacement"].source = displacement;
        new_source.slots["displacement"].name = "Displacement";
        new_source.slots["data0"].name = "Parameter";
        new_source.slots["data0"].source = parameter;
        new_source.slots["data1"].name = "";
        new_source.slots["data2"].name = "";
        new_source.slots["data3"].name = "";
        new_source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_source.slot_order = ["data0", "displacement"];

        if(parameter.layer.zsteps && parameter.layer.zsteps.length > 1){
            new_source.parameters["z_level"].options = [];
            parameter.layer.zsteps.forEach(zstep => {
                new_source.parameters["z_level"].addOption(zstep);
            });
            new_source.parameters["z_level"].value = parameter.layer.zsteps[0];
            
        }
        
        let new_layer = new RenderLayer();
        new_layer.typeHint = "ColormapScalar";
        new_layer.source = new_source;
        new_layer.name = new_source.name;
        new_layer.visible = true;
        if(parameter.layer.default_overlay){
            new_layer.overlayHint = parameter.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        let colormap_filter = new ColormapFilter(parameter);
        new_layer.unit = parameter.layer.unit;
        new_layer.filterPipeline.push(colormap_filter);
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createColormapDifferenceLayer(base_parameter: SourceLayerInfo, delta_parameter: SourceLayerInfo, displacement: SourceLayerInfo = null): RenderLayer{
        let new_source = new TileRenderSource();
        new_source.name = "Difference between " + base_parameter.instance_name + "(" + base_parameter.layer_name + ")" + " and " + delta_parameter.instance_name + "(" + delta_parameter.layer_name + ")";

        new_source.slots["displacement"].source = displacement;
        new_source.slots["displacement"].name = "Displacement";
        new_source.slots["data0"].name = "Base Parameter";
        new_source.slots["data0"].source = base_parameter;
        new_source.slots["data1"].name = "Delta Parameter";
        new_source.slots["data1"].source = delta_parameter;
        new_source.slots["data1"].mixing = Mat4.identity().mul_number(-1);
        new_source.slots["data2"].name = "";
        new_source.slots["data3"].name = "";
        new_source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_source.slot_order = ["data0", "data1", "displacement"];

        if(base_parameter.layer.zsteps && base_parameter.layer.zsteps.length > 1){
            new_source.parameters["z_level"].options = [];
            base_parameter.layer.zsteps.forEach(zstep => {
                new_source.parameters["z_level"].addOption(zstep);
            });
            new_source.parameters["z_level"].value = base_parameter.layer.zsteps[0];
            
        }
        
        let new_layer = new RenderLayer();
        new_layer.typeHint = "ColormapDifference";
        new_layer.source = new_source;
        new_layer.name = new_source.name;
        new_layer.visible = true;
        if(base_parameter.layer.default_overlay){
            new_layer.overlayHint = base_parameter.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        let base_colormap = new ColormapFilter(base_parameter);
        let base_min = base_colormap.parameters["colormap_min"].value;
        let base_max = base_colormap.parameters["colormap_max"].value;
        let delta_colormap = new ColormapFilter(delta_parameter)
        let delta_min = base_colormap.parameters["colormap_min"].value;
        let delta_max = base_colormap.parameters["colormap_max"].value;
        let colormap_filter = new ColormapFilter(base_parameter);
        colormap_filter.parameters["colormap_min"].value = base_min - delta_max;
        colormap_filter.parameters["colormap_max"].value = base_max - delta_min;
        new_layer.unit = base_parameter.layer.unit;
        new_layer.filterPipeline.push(colormap_filter);
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createImageLayer(image: SourceLayerInfo, displacement: SourceLayerInfo = null): RenderLayer{
        let new_source = new TileRenderSource();
        new_source.name = image.instance_name + "(" + image.layer_name + ")";
        new_source.slots["displacement"].source = displacement;
        new_source.slots["displacement"].name = "Displacement";
        new_source.slots["data0"].name = "Image";
        new_source.slots["data0"].source = image;
        new_source.slots["data1"].name = "";
        new_source.slots["data2"].name = "";
        new_source.slots["data3"].name = "";
        new_source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_source.slot_order = ["data0", "displacement"];
        
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Image";
        new_layer.source = new_source;
        new_layer.name = new_source.name;
        new_layer.visible = true;
        if(image.layer.default_overlay){
            new_layer.overlayHint = image.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ColorOverlay";
        }
        new_layer.unit = null;

        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createPointsLayer(points: SourceLayerInfo): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Points";
        new_layer.source = new PointRenderSource();
        new_layer.source.slots["points"].source = points;
        new_layer.source.slots["points"].name = "Points";
        new_layer.source.name = points.instance_name + "(" + points.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;
        new_layer.source.parameters["value_sizing_zero"].setUnit(points.layer.unit).value = points.layer.datarange[0];
        new_layer.source.parameters["value_sizing_one"].setUnit(points.layer.unit).value = points.layer.datarange[1];

        new_layer.source.slot_order = ["points"];

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        new_layer.filterPipeline.push(new ColormapFilter(points));
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.unit = points.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(points.layer.default_overlay){
            new_layer.overlayHint = points.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createPieChartPointsLayer(attribute1: SourceLayerInfo, extra_attributes: SourceLayerInfo[] = []): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "PieChartPoints";
        new_layer.source = new PieChartPointRenderSource();
        new_layer.source.slots["attribute1"].source = attribute1;
        new_layer.source.slots["attribute1"].name = "Attribute 1";
        if(attribute1){
            new_layer.source.name = attribute1.instance_name + "(" + attribute1.layer_name + ")";
            new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;
            new_layer.source.parameters["value_sizing_zero"].setUnit(attribute1.layer.unit).value = attribute1.layer.datarange[0];
            new_layer.source.parameters["value_sizing_one"].setUnit(attribute1.layer.unit).value = attribute1.layer.datarange[1];

            new_layer.ticksHint = {};
            new_layer.ticksHint[(1/3)] = attribute1.layer.long_name ||attribute1.layer.name;

            let slotnum = 2;
            for(let sli of extra_attributes){
                new_layer.source.slots["attribute"+ slotnum].source = sli;
                new_layer.ticksHint[(1/3)+((slotnum - 1)*0.8)] = sli.layer.long_name || sli.layer.name;
                slotnum ++;
            }

            let min_sum = attribute1.layer.datarange[0] || 0;
            let max_sum = attribute1.layer.datarange[1] || 0;
            for(let sli of extra_attributes){
                min_sum += sli.layer.datarange[0] || 0;
                max_sum += sli.layer.datarange[1] || 0;
            }

            new_layer.source.parameters["value_sizing_zero"].value = min_sum;
            new_layer.source.parameters["value_sizing_one"].value = max_sum;
            new_layer.source.parameters["value_sizing_enabled"].value = true;

        }
        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        let cmap = new ColormapFilter();
        cmap.parameters["colormap"].value = "Set1";
        cmap.parameters["colormap_min"].value = 0;
        cmap.parameters["colormap_max"].value = 7;
        new_layer.filterPipeline.push(cmap);
        new_layer.filterPipeline.push(new HillShadingFilter()); //No hillshading for now
        new_layer.unit = attribute1?.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(attribute1?.layer.default_overlay){
            new_layer.overlayHint = attribute1.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createOceanLayer(): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Ocean";
        new_layer.source = new SphereRenderSource();
        new_layer.source.name = "Ocean Surface";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        let hill_shading = new HillShadingFilter();
        hill_shading.parameters["shading_intensity"].setValue(1);
        hill_shading.parameters["shading_falloff"].setValue(1);
        hill_shading.parameters["shading_falloff"].setRange(0,2);
        hill_shading.parameters["brightness"].setValue(0.5);
        new_layer.filterPipeline.push(hill_shading);
        new_layer.compositionFilter = CompositionFilter.Ocean();
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createAtmosphereLayer(): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Atmosphere";
        new_layer.source = new QuadRenderSource();
        new_layer.source.name = "Atmosphere";

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        let hill_shading = new HillShadingFilter();
        hill_shading.parameters["shading_intensity"].setValue(1);
        hill_shading.parameters["shading_falloff"].setValue(1);
        hill_shading.parameters["shading_falloff"].setRange(0,2);
        hill_shading.parameters["brightness"].setValue(0.5);
        //new_layer.filterPipeline.push(hill_shading);
        new_layer.compositionFilter = CompositionFilter.Atmosphere();
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }


    static createLinesLayer(lines: SourceLayerInfo): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Lines";
        new_layer.source = new LineRenderSource();
        new_layer.source.slots["lines"].source = lines;
        new_layer.source.slots["lines"].name = "Lines";
        new_layer.source.name = lines.instance_name + "(" + lines.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_layer.source.slot_order = ["lines"];

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        new_layer.filterPipeline.push(new ColormapFilter(lines));
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.unit = lines.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(lines.layer.default_overlay){
            new_layer.overlayHint = lines.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createVector2Layer(datau: SourceLayerInfo, datav: SourceLayerInfo, displacement: SourceLayerInfo = null): RenderLayer {
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Vector2";
        new_layer.source = new VectorTracerRenderSource();
        new_layer.source.slots["datau"].source = datau;
        new_layer.source.slots["datav"].source = datav;
        new_layer.source.slots["displacement"].source = displacement;
        new_layer.source.name = datau.instance_name + "(" + datau.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_layer.source.slot_order = ["datau", "datav", "displacement"];

        if(datau.layer.zsteps && datau.layer.zsteps.length > 1){
            new_layer.source.parameters["z_level"].options = [];
            datau.layer.zsteps.forEach(zstep => {
                new_layer.source.parameters["z_level"].addOption(zstep);
            });
            new_layer.source.parameters["z_level"].value = datau.layer.zsteps[0];
        }

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        //TODO improve colormap ranging
        let u_colormap = new ColormapFilter(datau);
        let u_min = u_colormap.parameters["colormap_min"].value;
        let u_max = u_colormap.parameters["colormap_max"].value;
        let v_colormap = new ColormapFilter(datav)
        let v_min = u_colormap.parameters["colormap_min"].value;
        let v_max = u_colormap.parameters["colormap_max"].value;
        let colormap_filter = new ColormapFilter(datau);
        let total_min = Math.min(Math.abs(u_min), Math.abs(u_max), Math.abs(v_min), Math.abs(v_max), 0);
        let total_max = Math.max(Math.abs(u_min), Math.abs(u_max), Math.abs(v_min), Math.abs(v_max));
        colormap_filter.parameters["colormap_min"].value = total_min;
        colormap_filter.parameters["colormap_max"].value = total_max;
        new_layer.filterPipeline.push(colormap_filter);
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.unit = datau.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(datau.layer.default_overlay){
            new_layer.overlayHint = datau.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createVector2ArrowLayer(datau: SourceLayerInfo, datav: SourceLayerInfo, displacement: SourceLayerInfo = null): RenderLayer {
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Vector2Arrow";
        new_layer.source = new VectorTracerArrowRenderSource();
        new_layer.source.slots["datau"].source = datau;
        new_layer.source.slots["datav"].source = datav;
        new_layer.source.slots["displacement"].source = displacement;
        new_layer.source.name = datau.instance_name + "(" + datau.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;

        new_layer.source.slot_order = ["datau", "datav", "displacement"];

        if(datau.layer.zsteps && datau.layer.zsteps.length > 1){
            new_layer.source.parameters["z_level"].options = [];
            datau.layer.zsteps.forEach(zstep => {
                new_layer.source.parameters["z_level"].addOption(zstep);
            });
            new_layer.source.parameters["z_level"].value = datau.layer.zsteps[0];
        }

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        //TODO improve colormap ranging
        let u_colormap = new ColormapFilter(datau);
        let u_min = u_colormap.parameters["colormap_min"].value;
        let u_max = u_colormap.parameters["colormap_max"].value;
        let v_colormap = new ColormapFilter(datav)
        let v_min = u_colormap.parameters["colormap_min"].value;
        let v_max = u_colormap.parameters["colormap_max"].value;
        let colormap_filter = new ColormapFilter(datau);
        let total_min = Math.min(Math.abs(u_min), Math.abs(u_max), Math.abs(v_min), Math.abs(v_max), 0);
        let total_max = Math.max(Math.abs(u_min), Math.abs(u_max), Math.abs(v_min), Math.abs(v_max));
        colormap_filter.parameters["colormap_min"].value = total_min;
        colormap_filter.parameters["colormap_max"].value = total_max;
        new_layer.filterPipeline.push(colormap_filter);
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.unit = datau.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(datau.layer.default_overlay){
            new_layer.overlayHint = datau.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createPointsLayerHACK(points: SourceLayerInfo): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "PointsHACK";
        new_layer.source = new PointRenderSourceHack();
        new_layer.source.slots["points"].source = points;
        new_layer.source.slots["points"].name = "Points";
        new_layer.source.name = points.instance_name + "(" + points.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;
        new_layer.source.parameters["value_sizing_zero"].setUnit(points.layer.unit).value = points.layer.datarange[0];
        new_layer.source.parameters["value_sizing_one"].setUnit(points.layer.unit).value = points.layer.datarange[1];

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        new_layer.filterPipeline.push(new ColormapFilter(points));
        new_layer.unit = points.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(points.layer.default_overlay){
            new_layer.overlayHint = points.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createVec2PointsLayer(points: SourceLayerInfo): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Vec2Points";
        new_layer.source = new VectorPointRenderSource();
        new_layer.source.slots["points"].source = points;
        new_layer.source.slots["points"].name = "Points";
        new_layer.source.name = points.instance_name + "(" + points.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;
        new_layer.source.parameters["value_sizing_zero"].setUnit(points.layer.unit).value = points.layer.datarange[0];
        new_layer.source.parameters["value_sizing_one"].setUnit(points.layer.unit).value = points.layer.datarange[1];

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        new_layer.filterPipeline.push(new ColormapFilter(points));
        new_layer.unit = points.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(points.layer.default_overlay){
            new_layer.overlayHint = points.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }

    static createVec2PointsLayerHACK(points: SourceLayerInfo): RenderLayer{
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Vec2PointsHACK";
        new_layer.source = new VectorPointRenderSourceHack();
        new_layer.source.slots["points"].source = points;
        new_layer.source.slots["points"].name = "Points";
        new_layer.source.name = points.instance_name + "(" + points.layer_name + ")";
        new_layer.source.parameters["displacement_offset"].setUnit("m").value = 0;
        new_layer.source.parameters["value_sizing_zero"].setUnit(points.layer.unit).value = points.layer.datarange[0];
        new_layer.source.parameters["value_sizing_one"].setUnit(points.layer.unit).value = points.layer.datarange[1];

        new_layer.name = new_layer.source.name;
        new_layer.visible = true;
        new_layer.filterPipeline.push(new ColormapFilter(points));
        new_layer.unit = points.layer.unit;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {}));
        if(points.layer.default_overlay){
            new_layer.overlayHint = points.layer.default_overlay;
        } else {
            new_layer.overlayHint = "ScalarOverlay";
        }
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }))
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        return new_layer;
    }
}

