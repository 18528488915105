import { color } from "@/d3";
import { Parameter } from "@/modules/Parameter";
import { ColormapFilter } from "@/modules/renderfilters/ColormapFilter";
import { HillShadingFilter } from "@/modules/renderfilters/HillShadingFilter";
import { CompositionFilter } from "@/modules/renderfilters/RenderFilter";
import { RenderLayer } from "@/modules/RenderLayer";
import { ComputedLineRenderSource } from "@/modules/rendersources/ComputedLineRenderSource";
import { ObjectRenderSource } from "@/modules/rendersources/ObjectRenderSource";
import { Coord, GeoPoint, UEC } from "@/modules/tile";
import { Vec3 } from "@/modules/vecmat";
import { Services } from "./Services";

export class MeasurementChangedEvent extends Event{
    public layer: RenderLayer;
    constructor(layer: RenderLayer){
        super("MeasurementChanged");
        this.layer = layer;
    }
}

export class MetaLayerService extends EventTarget{
    constructor(){
        super();
    }

    private getScaleLayerName(): string{
        let selected_layer = Services.RenderLayerService.getSelectedLayer();
        return `Scale on layer ${selected_layer.name}`;
    }

    public createOrMoveScaleLayer(){
        let name = this.getScaleLayerName();
        let layer = Services.RenderLayerService.getLayers().find(l => l.name == name);
        if(!!layer){
            let position = Services.LayerMetadataService.coord;
            let height = Services.LayerMetadataService.z;
            let coord = Coord.from_UEC(position);  
            let source = layer.source;
            source.parameters["dx"].setValue(coord.lon);
            source.parameters["dy"].setValue(coord.lat);
            source.parameters["h"].setValue(height);
            let overlay = Services.OverlayService.getOverlaysByLayer(layer)[0];
            if(!!overlay){
                overlay.location = position;
                overlay.height = height;
                overlay.updateScreenPosition();
            }else{
                Services.OverlayService.addOverlay(position, height, layer, null);
            }
            Services.AdaptivePerformanceService.RequestRerender();
        }else{
            this.createScaleLayer();
        }
    }

    public createScaleLayer(position?: UEC, height?: number){
        if(!position){
            position = Services.LayerMetadataService.coord;
        }
        if(!height){
            height = Services.LayerMetadataService.z;
        }
        let coord = Coord.from_UEC(position);  

        let model = Services.ModelRepositoryService.get_model("cube");
        let new_source = new ObjectRenderSource(model);      
        new_source.parameters["dx"].setValue(coord.lon);
        new_source.parameters["dy"].setValue(coord.lat);
        new_source.parameters["h"].setValue(height);
        //new_source.parameters["scale"].setValue(new Vec3(0.001,0.001,0.001));
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Model";
        new_layer.source = new_source;
        new_layer.visible = true;
        new_layer.unit = null;
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }));
        new_layer.overlayHint = "ScaleOverlay";
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        new_layer.name = this.getScaleLayerName();

        let selected_layer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addLayer(new_layer);
        Services.OverlayService.addOverlay(position, height, new_layer, null);

        //let overlay_id = Services.OverlayService.addOverlay(position, height, new_layer, null);

        Services.RenderLayerService.selectLayer(selected_layer);
        Services.AdaptivePerformanceService.RequestRerender();
        console.log("Added Layer", new_layer);
    }

    private getMeasurementLayerName(): string{
        let selected_layer = Services.RenderLayerService.getSelectedLayer();
        return `Measurement on layer ${selected_layer.name}`;
    }

    public createOrModifyMeasurement(){
        let name = this.getMeasurementLayerName();
        let layer = Services.RenderLayerService.getLayers().find(l => l.name == name);
        if(!!layer){
            if(Services.LayerMetadataService.isUpToDate){
                let position = Services.LayerMetadataService.coord;
                let height = Services.LayerMetadataService.z;
                let computed_line_render_source = layer.source as ComputedLineRenderSource;
                computed_line_render_source.points.push(computed_line_render_source.points[computed_line_render_source.points.length -1]); //Duplicate last point as start of line segment
                let point = new GeoPoint(position,height,computed_line_render_source.points.length,0);
                computed_line_render_source.points.push(point)
                computed_line_render_source.updateDataBuffer()
                let overlay = Services.OverlayService.getOverlaysByLayer(layer)[0];
                if(!!overlay){
                    overlay.location = position;
                    overlay.height = height;
                    overlay.updateScreenPosition();
                }else{
                    Services.OverlayService.addOverlay(position, height, layer, null);
                }
                this.dispatchEvent(new MeasurementChangedEvent(layer));
                Services.AdaptivePerformanceService.RequestRerender();
            }
        }else{
            this.createMeasurementLayer();
        }
    }

    public createMeasurementLayer(){
        let position = Services.LayerMetadataService.coord;
        let height = Services.LayerMetadataService.z;
        let start_point = new GeoPoint(position,height,0,0);
        let new_source = new ComputedLineRenderSource([start_point, start_point]);
        new_source.parameters.line_width.value = 4;
        let new_layer = new RenderLayer();
        new_layer.typeHint = "Line";
        new_layer.source = new_source;
        new_layer.visible = true;
        new_layer.unit = null;
        let colormap = new ColormapFilter(null);
        colormap.parameters.colormap.setValue("prism");
        new_layer.filterPipeline.push(colormap);
        new_layer.filterPipeline.push(new HillShadingFilter());
        new_layer.compositionFilter = (new CompositionFilter(Services.GLService.Modules.compositing.blending, {
            "layer_opacity": new Parameter("Opacity", 1.0, "number", true).setRange(0, 1).setStep(0.1)
        }));
        new_layer.overlayHint = "MeasurementOverlay";
        new_layer.compositionFilter.parameters["layer_opacity"].shader_name = "layer_opacity";
        new_layer.name = this.getMeasurementLayerName();
        let selected_layer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addLayer(new_layer);
        Services.OverlayService.addOverlay(position, height, new_layer, null);
        //let overlay_id = Services.OverlayService.addOverlay(position, height, new_layer, null);
        Services.RenderLayerService.selectLayer(selected_layer);
        Services.AdaptivePerformanceService.RequestRerender();
        this.dispatchEvent(new MeasurementChangedEvent(new_layer));
        console.log("Added Layer", new_layer);
    }
}