//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { StitchedTileData } from "../../services/StitchedTilesService";
import { UEC, UECArea, Tile } from "../tile";
import { SourceLayerInfo } from "@/services/SourceInfoService";

export class SphereRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.sphereSource;
        this.name = "SphereRenderSource";
        this.slots = {};
        this.parameters = {
            displacement_scale: Services.SettingsService.getSetting("Exaggeration"),
            displacement_correction: new Parameter("Displacement Scaling", 1, "number", true).setShaderName("displacement_unit"),
            displacement_offset: new Parameter("Vertical Offset", 0, "number", true),
        };

        this.parameters["displacement_offset"].setShaderName("displacement_offset")
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        return[1, 1];
    }

    getExtent(): UECArea {
        return new UECArea(new UEC(0, 0), new UEC(1, 1));
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    requestStitchedTiles(){}

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        super.execute(context);

        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        let buff = Services.GLService.Geometries.tile;
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 2, context.gl.FLOAT, false, 0, 0);

        let req_tiles: Tile[];
        let layer_extent = this.getExtent();
        req_tiles = Services.RequiredTilesService.getRequiredTilesBounded(layer_extent, [this.applyScaling(0), this.applyScaling(0)]);
        let cam_position = Services.PositionService.getCameraPositionFiltered();
        req_tiles.forEach(tile => {
            switch (Services.PositionService.projection_mode) {
                case "EQUIRECT": {
                    if(cam_position.Longitude < -90 && tile.position.x > 0.5) {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x - 1, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    } else if (cam_position.Longitude > 90 && tile.position.x < 0.5) {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x + 1, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    } else {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    }
                        break;
                }
                case "SPHERE": {
                    break;
                }
                case "POLAR": {
                    break;
                }
            }
            context.gl.uniform2f(this.shader.uniforms["tile_coord"], tile.position.x, tile.position.y);
            context.gl.uniform2f(this.shader.uniforms["tile_size"], tile.size.x, tile.size.y);

            context.gl.drawArrays(context.gl.TRIANGLES, buff.start, buff.length);
        });
        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
    }
}
