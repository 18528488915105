//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4, Vec3, Mat3 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { ArrayData } from "../../services/TileCacheService";
import { Coord, UEC, UECArea } from "../tile";
import { ModelObject } from "../ModelObject";
import { PositionService } from "@/services/PositionService";

export class ObjectRenderSource extends RenderSource {
    private model: ModelObject;
    constructor(model: ModelObject) {
        super();
        this.model = model;
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.model;
        this.name = "ObjectRenderSource";
        this.parameters = {
            "scale": new Parameter("Scale", new Vec3(1.0,1.0,1.0), "vector3D", true).setShaderName("scale"),
            "dx": new Parameter("Longitude", 0, "number", true, false).setRange(-180, 180),
            "dy": new Parameter("Latitude", 0, "number", true, false).setRange(-90, 90),
            "h": new Parameter("Height", 0, "number", true, false)
        };
        this.slots = {
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        return [1,1];
    }

    getExtent(): UECArea {
        return UECArea.fromPoints([UEC.from_Coord(new Coord(
            (this.parameters["dy"].value),
            (this.parameters["dx"].value)
        ))]);
    }

    applyScaling(val: number): number {
        return 1 + val / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + val / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {   
        super.execute(context);
        
        switch (Services.PositionService.projection_mode) {
            case "EQUIRECT": {
                let cam_position = Services.PositionService.getCameraPositionFiltered();
                let x_offs = this.parameters["dx"].value - cam_position.Longitude;
                let y_offs = this.parameters["dy"].value - cam_position.Latitude;
                let scaling_mat = Mat3.identity().mul_number(1/Services.PositionService.camera_distance_filtered).extend();
                let shift_mat = Mat3.identity().translate(new Vec3(y_offs / 180 * Math.PI, -x_offs / 180 * Math.PI * Math.cos(cam_position.Latitude / 180 * Math.PI), this.parameters["h"].value / EARTH_RADIUS))
                let rotmat = Mat3.rot(cam_position.Azimuth / 180 * Math.PI, new Vec3(0, 0, -1)).extend()
                let wtransform = scaling_mat.mul_mat4(rotmat).mul_mat4(shift_mat);
                context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, wtransform.as_typed());//.mul_mat4(shift_mat).as_typed());
                break;
            }
            case "SPHERE": {
                let vertical_mat = Mat3.identity().translate(new Vec3(0, 0, 1 + (this.parameters["h"].value / EARTH_RADIUS)));
                let phi = (90 + this.parameters["dx"].value) / 180 * Math.PI; // ???????
                let lambda = (90 - this.parameters["dy"].value) / 180 * Math.PI;
                let rot_mat = Mat3.rot(phi, new Vec3(0, 0, 1)).mul_mat3(Mat3.rot(lambda, new Vec3(1, 0, 0))).extend().mul_mat4(vertical_mat);
                context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(rot_mat).as_typed());
                break;
            }
            case "POLAR": {
                break;
            }
        }

        context.gl.enable(context.gl.DEPTH_TEST);

        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.model.vertexData);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 0, 0);

        context.gl.enableVertexAttribArray(this.shader.attributes["normal"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.model.normalData);
        context.gl.vertexAttribPointer(this.shader.attributes["normal"], 3, context.gl.FLOAT, false, 0, 0);

        context.gl.drawArrays(context.gl.TRIANGLES, 0, this.model.vertexCount);

        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.gl.disableVertexAttribArray(this.shader.attributes["normal"]);
        //context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
    }
}
