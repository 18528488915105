






































































































import Vue from 'vue'
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import { DialogBox, DraggingOrientation } from '../services/DialogBoxService';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    props: {
        box_id: {
            type: String,
            required: true
        },
        dbox: {
            type: DialogBox,
            required: true
        }
    },
    components: {
        SvgIcon
    }
})
export default class DialogBoxComponent extends Vue{
    draggingOrientation: DraggingOrientation;
    startpos: DOMRect;
    mousestart: {x: number, y: number};

    mounted() {
        Services.InteractionService.mouseMoveHandlers.set("dialog_"+ this.$props.box_id + "_resize", (e) => {
            if(e.buttons != 1) return;
            let dx = e.clientX - this.mousestart.x;
            let dy = e.clientY - this.mousestart.y;
            let box = this.$el as HTMLDivElement;
            if(this.draggingOrientation & DraggingOrientation.Left) {
                box.style.width = (this.startpos.width - dx) + "px";
                this.$props.dbox.pos_x = (this.startpos.left + dx) / window.innerWidth * 100;
            }
            
            if(this.draggingOrientation & DraggingOrientation.Up) {
                box.style.height = (this.startpos.height - dy) + "px";
                this.$props.dbox.pos_y = (this.startpos.top + dy) / window.innerHeight * 100;
            }
            
            if(this.draggingOrientation & DraggingOrientation.Right) {
                box.style.width = (this.startpos.width + dx) + "px";
            }
            
            if(this.draggingOrientation & DraggingOrientation.Down) {
                box.style.height =( this.startpos.height + dy) + "px";
            }
            this.$forceUpdate();
        })

        Services.InteractionService.mouseMoveHandlers.set("dialog_"+ this.$props.box_id + "_move", (e) => {
            if(e.buttons != 1) return;
            let dx = e.clientX - this.mousestart.x;
            let dy = e.clientY - this.mousestart.y;
            this.$props.dbox.pos_x = (this.startpos.left + dx) / window.innerWidth * 100;
            this.$props.dbox.pos_y = (this.startpos.top + dy) / window.innerHeight * 100;
            this.validate_position();
            this.$forceUpdate();
        })
    }

    dialog_mousedown(e) {
        Services.DialogBoxService.focus(this.$props.id);
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id, e);
    }

    closeDialog(e) {
        Services.DialogBoxService.remove(this.$props.box_id);
    }

    resize_start(e, mode: DraggingOrientation) {
        Services.DialogBoxService.focus(this.$props.box_id);
        this.draggingOrientation = mode;
        this.startpos = this.$el.getBoundingClientRect();
        this.mousestart = {x: e.clientX, y: e.clientY};
        this.$props.dbox.pos_x = this.startpos.left / window.innerWidth * 100;
        this.$props.dbox.pos_y = this.startpos.top / window.innerHeight * 100;
        this.$props.dbox.referenceTransform = "translate(0%, 0%)";
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id + "_resize", e);
    }

    move_start(e) {
        Services.DialogBoxService.focus(this.$props.box_id);
        this.mousestart = {x: e.clientX, y: e.clientY};
        this.startpos = this.$el.getBoundingClientRect();
        this.$props.dbox.pos_x = this.startpos.left / window.innerWidth * 100;
        this.$props.dbox.pos_y = this.startpos.top / window.innerHeight * 100; 
        this.$props.dbox.referenceTransform = "translate(0%, 0%)";
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id + "_move", e);
    }

    validate_position(){
        this.$props.dbox.pos_x = Math.min(Math.max(0, this.$props.dbox.pos_x), 90);
        this.$props.dbox.pos_y = Math.min(Math.max(0, this.$props.dbox.pos_y), 90);
    }
}
