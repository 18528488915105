






































































































































































import { KeyDownEvent } from '../services/InteractionService';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Vec3 } from '../modules/vecmat';
import { FormattingService } from '../services/FormattingService';
import { GLBarrier, Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
import NumberInput from './guikit/numberinput.vue';
import FoldGroup from './guikit/foldGroup.vue';
import { Parameter } from '../modules/Parameter';
import { SettingsChangedEvent } from '@/services/SettingsService';
import { ProjectorConfigurationService } from '../services/ProjectorConfigurationService';
@Component({
    components: {
        SvgIcon,
        NumberInput,
        FoldGroup
    }
})
export default class Settings extends Vue{
    private shown = false;
    stupid = true;

    //ScalingFactor (Render Resolution)
    private ScalingFactor: number = null;
    private scalingFactorChanged(){
        Services.SettingsService.getSetting("ScalingFactor").value = this.ScalingFactor;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    //Target Tile Count (Data Resolution)
    private TargetTileCount: number = null;
    private targetTileCountChanged(){
        Services.SettingsService.getSetting("TargetTileCount").value = this.TargetTileCount;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    //Always Rerender
    private AlwaysRerender: boolean = null;
    setAlwaysRerender(e){
        Services.SettingsService.getSetting("AlwaysRerender").value = e.target.checked;
    }

    //ClearColor (Background Color)
    private ClearColor: string = null;
    private setClearColor(){
        console.log(this.ClearColor);
        let color = FormattingService.color_hex_to_vec3(this.ClearColor);
        Services.SettingsService.getSetting("ClearColor").value = color;
    }

    //WaitForLoad (Slow down playback)
    private WaitForLoad: boolean = null;
    setWaitForLoad(){
        Services.SettingsService.getSetting("WaitForLoad").value = this.WaitForLoad;
    }

    //Font Size
    private FontSize: number = null;
    private fontSizeChanged(){
        let fontsize = Services.SettingsService.getSetting("FontSize");
        let size = this.FontSize | 0;
        fontsize.value = Math.min(Math.max(fontsize.range[0], size), fontsize.range[1]);
        document.querySelector("html").style["font-size"] = fontsize.value + "pt";
        this.FontSize = fontsize.value;
    }

    //Clearing settings and cache
    async clearCache(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            window.indexedDB.deleteDatabase("data");
            window.location.search = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }
    async clearSettings(){
        localStorage["digitalearthviewer_settings"] = [];
        Services.SettingsService.skip_storing = true;
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            window.indexedDB.deleteDatabase("data");
            window.location.search = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }

    //Gamepad
    private Gamepads: Gamepad[] = null;
    private SelectedGamepad: Gamepad = null;
    selectGamepad(){
        Services.InteractionService.gamepadid = this.SelectedGamepad?.index;
        console.log("Set gamepad to ", this.SelectedGamepad?.id)

    }

    //Scene name, link creation
    private SceneName = "scene";
    private SceneError = "";
    private SceneLink = "";
    private CopySuccessful = false;
    async createLink(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            this.SceneLink = window.location.origin + "?" + result;
            Services.InitializationService.SceneName = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }
    private copyLinkToClipboard(){
        navigator.clipboard.writeText(this.SceneLink).catch((e) => this.SceneError = e).then(() => {
            this.CopySuccessful = true;
            setTimeout(() => this.CopySuccessful = false, 1000);
        });
    }

    //UIVisible
    private UIVisible: boolean = null;
    private setUIVisible(){
        Services.SettingsService.getSetting("UIVisible").value = this.UIVisible;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    //Dome Mode
    private DomeMode: string = null;
    selectDomeMode(){
        Services.SettingsService.getSetting("DomeMode").value = this.DomeMode;
        console.log("Setting Dome Mode to: ", this.DomeMode);
        Services.RenderService.forceDomeModeUpdate();
        this.stupid = !this.stupid;
    }

    //Display Region
    private DisplayIds: string[] = null;
    private DisplayRegion: string = null;
    selectDisplayRegion(){
        Services.SettingsService.getSetting("DisplayRegion").setValue(this.DisplayRegion);
        Services.ProjectorConfigurationService.setSelectedId(this.DisplayRegion);
        this.stupid = !this.stupid;
    }

    //Tilt angle
    private DomeTilt: number = null;
    private setDomeTilt(){
        Services.SettingsService.getSetting("DomeTile").setValue(this.DomeTilt);
    }

    private AxisOrders = ProjectorConfigurationService.genRotationOrders();
    private AxisOrderHumanReadable(n: string): string {
        let lut = {
            "NH": "-Yaw",
            "PH": "+Yaw",
            "NP": "-Pitch",
            "PP": "+Pitch",
            "NR": "-Roll",
            "PR": "+Roll"
        };
        return lut[n.substr(0, 2)] + " · " + lut[n.substr(2, 2)] + " · " + lut[n.substr(4, 2)];
    }
    private AxisOrder: string = null;
    private AxisOrderChanged(){
        Services.ProjectorConfigurationService.setRotationOrderString(this.AxisOrder);
        this.AxisOrder = Services.ProjectorConfigurationService.getRotationOrderString();
        this.stupid = !this.stupid;
    }

    //Synchronization
    private Synchronized: boolean = null;
    private setSynchronized(){
        if(this.Synchronized){
            if(Services.InitializationService.SceneName == ""){
                Services.InitializationService.SceneName = "default";
            }
            Services.SettingsService.getSetting("Synchronized").value = true;
            Services.SynchronizationService.tryStart();
        }else{
            Services.SynchronizationService.stop();
            Services.SettingsService.getSetting("Synchronized").value = false;
        }
    }

    //Version information
    private Versions = (() => Services.InitializationService.getVersionInfo())();
    
    //Sync scene
    private syncScene(){
        Services.SynchronizationService.syncScene();
    }

    //Constructor
    created(){
        this.registerProperty("ScalingFactor", () => Services.SettingsService.getValueOrDefault("ScalingFactor", 1));
        this.registerProperty("TargetTileCount", () => Services.SettingsService.getValueOrDefault("TargetTileCount", 8));
        this.registerProperty("AlwaysRerender", () => Services.SettingsService.getSetting("AlwaysRerender").value);
        this.registerProperty("ClearColor",  () => FormattingService.color_vec3_to_hex(Services.SettingsService.getSetting("ClearColor").value));
        this.registerProperty("WaitForLoad", () => Services.SettingsService.getSetting("WaitForLoad").value);
        this.registerProperty("FontSize", () => Services.SettingsService.getValueOrDefault("FontSize", 11));
        this.registerProperty("Gamepads", () => new Array(...navigator.getGamepads()).filter(e => !!e));
        this.registerProperty("SelectedGamepad", () => Services.InteractionService.getGamepad());
        this.registerProperty("UIVisible", () => Services.SettingsService.getSetting("UIVisible").value);
        this.registerProperty("DomeMode", () => Services.SettingsService.getValueOrDefault("DomeMode", "normal"));
        this.registerProperty("DisplayIds", () => Services.ProjectorConfigurationService.getConfigIds());
        this.registerProperty("DisplayRegion", () => Services.ProjectorConfigurationService.getSelectedId() || "");
        this.registerProperty("DomeTilt", () => Services.SettingsService.getValueOrDefault("DomeTilt",0));
        this.registerProperty("Synchronized", () => Services.SettingsService.getSetting("Synchronized").value);
        this.refreshValues();
    }

    //Setup
    private getters: {
        name: string,
        getter: ()=>any
    }[] = [];
    private registerProperty(name: string, getter: ()=>any){
        this.getters.push({
            name,
            getter
        });
    }
    private refreshValues(){
        for(let getter of this.getters){
            this[getter.name] = getter.getter();
        }
    }


    async mounted(){
        //Setup H key functionality
        Services.InteractionService.addEventListener("KeyDown", (k: KeyDownEvent) => {
            if(Services.InteractionService.getTarget() == "threedee"){
                if (k.key == "h"){
                    this.UIVisible = !Services.SettingsService.getSetting("UIVisible").value;
                }
            }
        });
        //Refresh when a setting changes
        Services.SettingsService.addEventListener("SettingsChanged", (e: SettingsChangedEvent) => {
            this.refreshValues();
            this.stupid = !this.stupid;
            this.$forceUpdate();
            });
        let gamepadhandler = (e: GamepadEvent) => {
            this.refreshValues();
            this.stupid = !this.stupid;
            this.$forceUpdate();
        };
        //Gamepads
        window.addEventListener("gamepaddisconnected",gamepadhandler);
        window.addEventListener("gamepadconnected", gamepadhandler);
        //Display Region
        GLBarrier.wait().then(() => {
            this.refreshValues();
        });
    }

    downloadLayerConfig(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        let renderlayertext = "data:application/json;base64," + btoa(renderlayers);
        let a_elem = document.getElementById('downloadlink');
        a_elem.setAttribute("href", renderlayertext);
        a_elem.click();
    }

    uploadLayerConfig(){
        document.getElementById('inputlayerfile').click();
    }

    async handleLayerConfig(e){
        let input: any = document.getElementById('inputlayerfile');
        let file: File = input.files[0];
        if(file){
            let resp = await new Response(file).json();
            try{
                await Services.InitializationService.load_initial_layers(resp);
            }catch(e){
                alert("Layer config could not be loaded: " + e);
            }
        }
    }

    
}
