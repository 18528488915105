
















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import { Coord, UEC, UECArea } from '../modules/tile';
import { SamplePoint, SamplePointData } from '../services/PointSamplingService';
import { SelectionMode } from '../services/SelectionService';
import { ServiceBarrier, Services } from '../services/Services';
import { Source, SourceLayerInfo } from '../services/SourceInfoService';
import * as Evergraphs from '../modules/Evergraphs'
import NumberInput from '../components/guikit/numberinput.vue';
import SvgIcon from '../components/guikit/svgIcon.vue';
import { timer } from '@/d3';
import { FormattingService } from '@/services/FormattingService';
import { filter } from 'vue/types/umd';

class Descriptor{
    type: "Layer" | "Index" | "Constant" | "NaN" | "Latitude" | "Longitude" | "Height" | "Time";
    sli: SourceLayerInfo;
    source: string;
    layer: string;
    constant: number;
    static DescriptorTypes = ["Layer", "Index", "Constant", "NaN", "Latitude", "Longitude", "Height", "Time"];
    toString(){
        switch(this.type){
            case "Layer": return `layer(${this.source}/${this.layer})`;
            case "Index": return "index";
            case "Constant": return `constant(${this.constant})`;
            case "NaN": return "nan";
            case "Latitude": return "latitude";
            case "Longitude": return "longitude";
            case "Height": return "height";
            case "Time": return "time";
        }
    }

    get sourceAndLayer(){
        return this.source + "/" + this.layer;
    }
    set sourceAndLayer(value: string){
        let parts = value.split("/");
        this.source = parts[0];
        this.layer = parts[1];
    }

    static NaN(){
        let x =  new Descriptor();
        x.type = "NaN";
        return x;
    }

    static Index(){
        let x =  new Descriptor();
        x.type = "NaN";
        return x;
    }

    static Time(){
        let x =  new Descriptor();
        x.type = "Time";
        return x;
    }

    static Latitude(){
        let x =  new Descriptor();
        x.type = "Latitude";
        return x;
    }

    static Longitude(){
        let x =  new Descriptor();
        x.type = "Longitude";
        return x;
    }

    static Height(){
        let x =  new Descriptor();
        x.type = "Height";
        return x;
    }

    static Constant(c: number)
    {
        let x =  new Descriptor();
        x.type = "Constant";
        x.constant = c;
        return x;
    }

    static Layer(sli: SourceLayerInfo){
        let x =  new Descriptor();
        x.type = "Layer";
        x.layer = sli.layer_name;
        x.source = sli.instance_name;
        x.sli = sli;
        return x;
    }
}

@Component({
    components: {
        NumberInput,
        SvgIcon
    },
    props:{
    }
})
export default class GraphComponent extends Vue{
    Descriptor = Descriptor;
    FormattingService = FormattingService;
    loadingState = "null";
    hasSVG = false;
    status = null;
    stupid = false;

    nightMode = true;

    resolution = 1000;

    selection: UECArea = Services.SelectionService.getSelectedRectangle();

    heightRange = {
        top: +99999999999,
        bottom: -99999999999
    };

    timeRange = {
        start: 0,
        end: 0
    };

    data_selection_type: "All" | "Layer" | "Selection" = "Layer";

    graphTypes = [
        {name: "Scatterplot", value: Evergraphs.ScatterPlot},
        {name: "XY-Plot", value: Evergraphs.XYPlot},
        {name: "Featherplot", value: Evergraphs.FeatherPlot},
        {name: "Roseplot", value: Evergraphs.RosePlot}
    ];
    selectedGraph = this.graphTypes[0].value;
    selectedGraphName = "Scatterplot";

    descriptors = {};

    layer: RenderLayer = null;

    layernames = [];

    dataframe = null;

    selectRange(which: "All" | "Layer" | "Selection" = "Layer"){
        this.data_selection_type = which;
        switch(which){
            case "All":
                {
                    this.selection = Services.RenderLayerService.getGlobalExtent();
                    let zrange = Services.RenderLayerService.getGlobalHeightRanges();
                    this.heightRange.top = zrange[1];
                    this.heightRange.bottom = zrange[0];
                    let trange = Services.TimeService.getOverallTimeRange();
                    this.timeRange.start = trange[0];
                    this.timeRange.end = trange[1];
                }
            break;
            case "Layer":
                {
                    let selectedLayer = Services.RenderLayerService.getSelectedLayer();
                    this.selection = selectedLayer.getExtent();
                    let zrange = selectedLayer.getVerticalBoundsNative();
                    zrange[0] *= Services.InitializationService.EARTH_RADIUS;
                    zrange[0] -= Services.InitializationService.EARTH_RADIUS;
                    zrange[1] *= Services.InitializationService.EARTH_RADIUS;
                    zrange[1] -= Services.InitializationService.EARTH_RADIUS;
                    this.heightRange.top = zrange[1];
                    this.heightRange.bottom = zrange[0];
                    let trange = selectedLayer.getTimeRange() ||[0,0];
                    this.timeRange.start = trange[0];
                    this.timeRange.end = trange[1];
                }
            break;
            case "Selection":
                {
                    this.selection = Services.SelectionService.getSelectedRectangle();
                    let zrange = Services.RenderLayerService.getGlobalHeightRanges();
                    this.heightRange.top = zrange[1];
                    this.heightRange.bottom = zrange[0];
                    let trange = Services.TimeService.getCurrentTimeRange();
                    this.timeRange.start = trange[0];
                    this.timeRange.end = trange[1];
                }
            break;
        }
    }

    selectedGraphChanged(){
        this.descriptors = {};
        this.selectedGraph = this.graphTypes.find(gt => gt.name == this.selectedGraphName).value;
        for(let input of this.selectedGraph.getRequiredInputs()){
            this.descriptors[input] = Descriptor.NaN();
        }

        let layer_sli = Services.RenderLayerService.getSelectedLayer().getSourceInfos()[0];
        console.log(layer_sli);
        if(layer_sli){
            switch(this.selectedGraphName){
                case "Scatterplot":
                    this.descriptors["X"] = Descriptor.Time();
                    this.descriptors["Y"] = Descriptor.Height();
                    this.descriptors["Value"] = Descriptor.Layer(layer_sli);
                break;
                case "XY-Plot":
                    this.descriptors["X"] = Descriptor.Time();
                    this.descriptors["Y"] = Descriptor.Layer(layer_sli);;
                break;
            }
        }
        this.update();
        

    }

    selectedTypeChanged(){
        console.log(this.descriptors);
        this.stupid = !this.stupid;
        this.$forceUpdate();

    }

    update(){
        this.stupid = !this.stupid;
        this.$forceUpdate();
    }

    created(){
        this.layer = Services.RenderLayerService.getSelectedLayer();
        this.selectedGraphChanged();
        this.layernames = Services.SourceInfoService.get_all_source_layer_infos()
            .filter(sli => sli.layer.layer_type == "ScalarTiles" || sli.layer.layer_type == "ScalarPoints")
            .map(sli => {
                return sli.instance_name + "/" + sli.layer_name;
            });
        this.selectRange("Layer");
    }

    async createGraph(){
        let string_descriptors = [];
        for(let name of this.selectedGraph.getRequiredInputs()){
            string_descriptors.push(this.descriptors[name].toString());
        }
        let datatableargs = {
            area: this.selection,
            heightrange: [this.heightRange.bottom, this.heightRange.top],
            timerange: [Math.floor(this.timeRange.start), Math.floor(this.timeRange.end)],
            max_number_of_points: this.resolution,
            descriptors: string_descriptors
        };

        console.log("datatableargs", datatableargs);

        this.status = "Requesting data";

        let response = await fetch("/datatable", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datatableargs)
        });

        let floats = new Float32Array(await response.arrayBuffer());

        //Convert to dataframe
        let data = new Evergraphs.DataFrame(this.selectedGraph.getRequiredInputs(), floats);
        console.log("Graph dataframe", data.length);
        this.dataframe = data;

        //Create the graph
        this.status = "Rendering graph";
        let svgel: Element = this.$refs["graphSVG"] as Element;



        //Estimate axes
        svgel.innerHTML = ""; //Clear graph
        //@ts-ignore
        let plot = new (this.selectedGraph.prototype.constructor)(svgel, data);
        //Collect labels
        for(let name of this.selectedGraph.getRequiredInputs()){
                plot.setAxisLabel(name, this.descriptors[name].toString());
                switch(this.descriptors[name].type){
                    case "Time":
                        plot.setAxisFormatter(name, (v) => FormattingService.time_to_string_shorter(v));
                        break;
                    case "Latitude":
                        plot.setAxisFormatter(name, (v) => FormattingService.latitude_to_string(v, 3));
                        break;
                    case "Longitude":
                        plot.setAxisFormatter(name, (v) => FormattingService.latitude_to_string(v, 3));
                        break;
                    case "Height":
                        plot.setAxisFormatter(name, (v) => FormattingService.num_to_string_unit_si(v, "m", 3));
                        break;
                    case "Layer":
                        let unit = this.descriptors[name].sli?.layer?.unit;
                        plot.setAxisFormatter(name, (v) => FormattingService.num_to_string_unit_si(v, unit, 3));
                        break;
                }
        }
        //Draw plot
        plot.draw();
        this.hasSVG = true;
        //this.selectedGraph.instantiator(dataarray, svgel, this.selectedGraph.dimensions[0], this.selectedGraph.dimensions[1]);
        this.status = null;
    }

    exportSVG(){
        let svgel: Element = this.$refs["graphSVG"] as Element;
        let svg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n` + svgel.outerHTML;
        let content = "data:image/svg+xml;base64," + btoa(svg);
        let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
        a_elem.setAttribute("download", "graph.svg");
        a_elem.setAttribute("href", content);
        a_elem.click();
    }

    exportCSV(){
        if(this.dataframe){
            let csv = this.dataframe.cols.map(name => this.descriptors[name].toString() || name).join(",") + "\n";
            csv += [... this.dataframe.rows()].map(row => row.join(",")).join("\n");
            let content = "data:image/svg+xml;base64," + btoa(csv);
            let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
            a_elem.setAttribute("download", "data.csv");
            a_elem.setAttribute("href", content);
            a_elem.click();
        }
    }

    async loadData(){
        let region = Services.SelectionService.getSelectedRectangle();
        this.loadingState = "Loading data";
        let result = await Services.PointSamplingService.sampleSelection(this.layer, region, (progress) => {
            this.loadingState = `Loading data: ${Math.ceil(progress * 100)}%`;
        });
        this.loadingState = `Loaded ${result.data.length} points`;
    }
    
}
