//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { StitchedTileData } from "../../services/StitchedTilesService";
import { UEC, UECArea, Tile } from "../tile";
import { SourceLayerInfo } from "@/services/SourceInfoService";

export class QuadRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.quadSource;
        this.name = "QuadRenderSource";
        this.slots = {};
        this.parameters = {};
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        return[1, 1];
    }

    getExtent(): UECArea {
        return new UECArea(new UEC(0, 0), new UEC(1, 1));
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    requestStitchedTiles(){}

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        super.execute(context);
        let geometry = Services.GLService.Geometries.quad;
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, geometry.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 2, context.gl.FLOAT, false, 0, 0);
        context.gl.drawArrays(context.gl.TRIANGLE_STRIP, geometry.start, geometry.length);
        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
    }
}
