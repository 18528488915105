//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import {AaBb, Vec3, Vec2} from './vecmat';

export class Coord{
    lat: number;
    lon: number;

    constructor(lat: number, lon: number){
        this.lat = lat;
        this.lon = lon;
    }

    static from_UEC(u: UEC): Coord{
        return new Coord(
            90.0 - (180 * u.y),
            (u.x * 360) - 180.0 
        )
    }

}

export class GeoPoint{
    public position: UEC;
    public height: number;
    public value: number;
    public time: number;
    constructor(position: UEC, height: number, value: number, time: number){
        this.position = position;
        this.height = height;
        this.value = value;
        this.time = time;
    }

    haversine_distance_with_heights(other: GeoPoint): number{
        let ground_distance = this.position.haversine_distance_to(other.position);
        let height_distance = Math.abs(other.height - this.height);
        return Math.sqrt(Math.pow(ground_distance, 2) + Math.pow(height_distance, 2));
    }
}

export class UEC{
    x: number;
    y: number;

    constructor(x: number, y: number){
        this.x = x;
        this.y = y;
    }

    static from_Coord(c: Coord): UEC{
        return new UEC(
            (c.lon + 180.0) / 360.0,
            1.0 - ((c.lat + 90.0) / 180.0)
        )
    }

    as_vec2(): Vec2{
        return new Vec2(this.x, this.y);
    }

    add(other: UEC): UEC{
        return new UEC(this.x + other.x, this.y + other.y);
    }

    subtract(other: UEC): UEC{
        return new UEC(this.x - other.x, this.y - other.y);
    }

    static from_ueclike(ueclike: Object): UEC{
        return new UEC(ueclike['x'], ueclike['y']);
    }

    haversine_distance_to(other: UEC): number{
        let self_coord = Coord.from_UEC(this);
        let other_coord = Coord.from_UEC(other);
        let lat1 = self_coord.lat;
        let lat2 = other_coord.lat;
        let lon1 = self_coord.lon;
        let lon2 = other_coord.lon;
        //Function from https://www.movable-type.co.uk/scripts/latlong.html
        const R = 6371e3; // metres
        let φ1= lat1 * Math.PI/180.0; // φ, λ in radians
        let φ2 = lat2 * Math.PI/180.0;
        let Δφ = (lat2-lat1) * Math.PI/180.0;
        let Δλ = (lon2-lon1) * Math.PI/180.0;
        let a = Math.sin(Δφ/2.0) * Math.sin(Δφ/2.0) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2.0) * Math.sin(Δλ/2.0);
        let c = 2.0 * Math.atan2(Math.sqrt(a), (Math.sqrt(1.0-a)));
        return R * c // in metres
    }
}

export class UECArea{
    public position: UEC;
    public extent: UEC;

    constructor(position: UEC, extent: UEC){
        this.position = position;
        this.extent = extent;
    }

    public static fromUECAreaLike(uecarealike: Object): UECArea{
        return new UECArea(
            UEC.from_ueclike(uecarealike[0]),
            UEC.from_ueclike(uecarealike[1])
        );
    }

    public static fromPositionExtentTuple(tuple: [UEC, UEC]): UECArea{
        return new UECArea(tuple[0], tuple[1]);
    }

    public static betweenTwoPoints(topLeft: UEC, bottomRight: UEC): UECArea{
        return new UECArea(topLeft, bottomRight.subtract(topLeft));
    }

    public static fromPoints(points: UEC[]): UECArea{
        //Check that we have any points at all
        if(points.length == 0){
            throw "Cannot create UEC Area without any points";
        }

        let min_x = points[0].x;
        let min_y = points[0].y;

        let max_x = points[0].x;
        let max_y = points[0].y;

        points.forEach(p => {
            if(p.x < min_x){
                min_x = p.x;
            }
            if(p.x > max_x){
                max_x = p.x;
            }
            if(p.y < min_y){
                min_y = p.y;
            }
            if(p.y > max_y){
                max_y = p.y;
            }
        });
        return UECArea.betweenTwoPoints(new UEC(min_x, min_y), new UEC(max_x, max_y));
    }

    public topLeft(): UEC{
        return this.position;
    }

    public bottomRight(): UEC{
        return this.position.add(this.extent);
    }
}



export class Tile{
    position: UEC;
    size: UEC;
    path: string;

    static from_XYWH(x: number, y: number, w: number, h: number, path: string): Tile{
        let t = new Tile();
        t.position = new UEC(x,y);
        t.size = new UEC(w,h);
        t.path = path;
        return t;
    }

    copy(): Tile{
        return Tile.from_XYWH(this.position.x, this.position.y, this.size.x, this.size.y, this.path);
    }

    in_extent(extent: UECArea): boolean {
        return (this.position.x + this.size.x > extent.position.x) && 
            (this.position.x < extent.position.x + extent.extent.x) &&
            (this.position.y + this.size.y > extent.position.y) &&
            (this.position.y < extent.position.y + extent.extent.y)
    }

    bounds(radius_min: number, radius_max: number): AaBb {
        let radius = radius_max;
        if(this.path == "W")return new AaBb(new Vec3(radius, radius, radius), new Vec3(-radius, -radius, -radius));
        if(this.path == "E")return new AaBb(new Vec3(radius, radius, radius), new Vec3(-radius, -radius, -radius));
        let point_worldspace = (p: Vec2) => {
            var px = (p.x1 + 0.5) * 2.0 * Math.PI;
            var py = -(p.x2 - 0.5) * Math.PI;
            var sp = new Vec3(
                Math.cos(px) * Math.cos(py),
                Math.sin(px) * Math.cos(py),
                Math.sin(py),
            );
            return sp;
        }
        //This is the naive version, you could probably write a much faster version if you take into account all implicit constraints on tile data.
        let p0 = point_worldspace(new Vec2(this.position.x, this.position.y));
        let p1 = point_worldspace(new Vec2(this.position.x + this.size.x, this.position.y));
        let p2 = point_worldspace(new Vec2(this.position.x, this.position.y + this.size.y));
        let p3 = point_worldspace(new Vec2(this.position.x + this.size.x, this.position.y + this.size.y));
        let res = AaBb.from_point(p0.mul(radius_min));
        res = res.add_vec3(p0.mul(radius_max));
        res = res.add_vec3(p1.mul(radius_min));
        res = res.add_vec3(p1.mul(radius_max));
        res = res.add_vec3(p2.mul(radius_min));
        res = res.add_vec3(p2.mul(radius_max));
        res = res.add_vec3(p3.mul(radius_min));
        res = res.add_vec3(p3.mul(radius_max));
        return res;
    }

    A(): Tile{
        return Tile.from_XYWH(
            this.position.x,
            this.position.y,
            this.size.x / 2.0,
            this.size.y / 2.0,
            this.path + "A"
        )
    }

    B(): Tile{
        return Tile.from_XYWH(
            this.position.x + (this.size.x / 2.0),
            this.position.y,
            this.size.x / 2.0,
            this.size.y / 2.0,
            this.path + "B"
        )
    }

    C(): Tile{
        return Tile.from_XYWH(
            this.position.x,
            this.position.y + (this.size.y / 2.0),
            this.size.x / 2.0,
            this.size.y / 2.0,
            this.path + "C"
        )
    }

    D(): Tile{
        return Tile.from_XYWH(
            this.position.x + (this.size.x / 2.0),
            this.position.y + (this.size.y / 2.0),
            this.size.x / 2.0,
            this.size.y / 2.0,
            this.path + "D"
        )
    }

    U(): Tile{
        return Tile.from_XYWH(
            this.position.x,
            this.position.y,
            this.size.x,
            this.size.y / 2.0,
            this.path + "U"
        )
    }

    L(): Tile{
        return Tile.from_XYWH(
            this.position.x,
            this.position.y + (this.size.y / 2.0),
            this.size.x,
            this.size.y / 2.0, 
            this.path + "L"
        )
    }

    parent(): Tile{
        if(this.path.length > 1) return Tile.from_tilepath(this.path.substring(0, this.path.length - 1));
        else return null;
    }

    posInTile(other: Tile): number[]{
        let lx = (this.position.x - other.position.x) / other.size.x;
        let ly = (this.position.y - other.position.y) / other.size.y;
        let sx = this.size.x / other.size.x;
        let sy = this.size.y / other.size.y;
        return [lx, ly, sx, sy];
    }

    split(): Tile[] {
        let width = 2.0 * this.size.x * Math.sin((this.position.y + this.size.y / 2.0) * Math.PI);
            let height = this.size.y;
            let aspect_ratio = width / height;
            if(aspect_ratio < 0.5){
                return [this.U(), this.L()];
            }else{
                return [this.A(), this.B(), this.C(), this.D()];
            }
    }

    //Gets tile bounds from the tile path
    static from_tilepath(tilepath: string): Tile{
        let t = null;
        if(tilepath.startsWith("W")){
            t = Tile.from_XYWH(0.0, 0.0, 0.5, 1.0, "W");
        }else if(tilepath.startsWith("E")){
            t = Tile.from_XYWH(0.5, 0.0, 0.5, 1.0, "E");
        }else throw new Error("Invalid tile path: \"\"");

        for(let c of [...tilepath.substring(1)]){
            let width = 2.0 * t.size.x * Math.sin((t.position.y + t.size.y / 2.0) * Math.PI);
            let height = t.size.y;
            let aspect_ratio = width / height;
            if(aspect_ratio < 0.5){
                if(c == 'U'){
                    t = t.U();
                }else if (c=='L'){
                    t = t.L();
                }else{
                    throw new Error("Invalid tile path: " + tilepath);
                }
            }else{
                switch(c){
                    case 'A': 
                        t = t.A();
                        break;
                    case 'B':
                        t = t.B();
                        break;
                    case 'C': 
                        t = t.C();
                        break;
                    case 'D':
                        t = t.D();
                        break;
                    default:
                        throw new Error("Invalid tile path: " + tilepath);
                }
            }
        }

        return t;
    }


    
    
}