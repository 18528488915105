import { Services } from "@/services/Services";
import { RenderLayer } from "./RenderLayer";
import { UECArea } from "./tile";

export type SerializedRenderGroup = {
    name: string,
    id: number,
    children: number[],
}

export class RenderGroup{
    public name: string;
    public id: number;
    public children: number[];

    constructor(name: string, id?: number){
        this.name = name;
        this.children = [];
        if(id){
            this.id = id;
        }else{
            this.id = Services.UIDService.getNextTopicId("layers");
        }
    }

    add(id: number){
        this.children.push(id);
    }

    remove(id: number){
        this.children = this.children.filter(l => l != id);
    }

    getLayerInstances(): RenderLayer[]{
        return this.children.filter(l => Services.RenderLayerService.isLayer(l)).map(l => Services.RenderLayerService.getLayerById(l));
    }

    getGroupInstances(): RenderGroup[]{
        return this.children.filter(g => Services.RenderLayerService.isGroup(g)).map(g => Services.RenderLayerService.getGroupById(g));
    }

    hasChild(id: number): Boolean {
        return this.children.some(c => {
            if(c == id)return true;
            if(Services.RenderLayerService.isGroup(c))
                return Services.RenderLayerService.getGroupById(c).hasChild(id);
            return false;
        })
    }

    getExtent(): UECArea{
        let extents: UECArea[] = [];
        for(let l of this.getLayerInstances()){
            let e = l.getExtent();
            if(e != null){
                extents.push(e);
            }
        }
        for(let g of this.getGroupInstances()){
            let e = g.getExtent();
            if(e != null){
                extents.push(e);
            }
        }
        if(extents.length > 0){
            let base_extent = extents[0];
            for(let i = 1; i < extents.length; i++){
                base_extent = UECArea.fromPoints([base_extent.topLeft(), base_extent.bottomRight(), extents[i].topLeft(), extents[i].bottomRight()]);
            }
            console.log("base_extent", base_extent);
            return base_extent;
        }else{
            return null;
        }
    }

    getVerticalBoundsWorldspace(): [number, number]{
        let bounds: [number, number][] = [];
        for(let l of this.getLayerInstances()){
            let b = l.getVerticalBoundsWorldspace();
            if(b != null){
                bounds.push(b);
            }
        }
        for(let g of this.getGroupInstances()){
            let b = g.getVerticalBoundsWorldspace();
            if(b != null){
                bounds.push(b);
            }
        }
        if(bounds.length > 0){
            let base_bounds = bounds[0];
            for(let i = 1; i < bounds.length; i++){
                base_bounds[0] = Math.min(base_bounds[0], bounds[i][0]);
                base_bounds[1] = Math.max(base_bounds[1], bounds[i][1]);
            }
            console.log("vertical_bounds", base_bounds);
            return base_bounds;
        }else{
            return null;
        }
    }

    getVerticalBoundsNative(): [number, number]{
        let bounds: [number, number][] = [];
        for(let l of this.getLayerInstances()){
            let b = l.getVerticalBoundsNative();
            if(b != null){
                bounds.push(b);
            }
        }
        for(let g of this.getGroupInstances()){
            let b = g.getVerticalBoundsNative();
            if(b != null){
                bounds.push(b);
            }
        }
        if(bounds.length > 0){
            let base_bounds = bounds[0];
            for(let i = 1; i < bounds.length; i++){
                base_bounds[0] = Math.min(base_bounds[0], bounds[i][0]);
                base_bounds[1] = Math.max(base_bounds[1], bounds[i][1]);
            }
            console.log("vertical_bounds_native", base_bounds);
            return base_bounds;
        }else{
            return null;
        }
    }

    index(id){
        return this.children.indexOf(id);
    }

    down(id: number){
        let index = this.index(id);
        if(index != this.children.length -1){
            let next_elem = this.children[index + 1];
            this.children[index + 1] = id;
            this.children[index] = next_elem;
        }
        Services.RenderLayerService.raiseLayersChanged();
    }

    up(layer_id: number){
        let index = this.index(layer_id);
        if(index != 0){
            let prev_elem = this.children[index - 1];
            this.children[index - 1] = layer_id;
            this.children[index] = prev_elem;
        }
        Services.RenderLayerService.raiseLayersChanged();
    }
   
    isFirstLayer(layer_id: number){
        return this.index(layer_id) == 0;
    }

    isLastLayer(layer_id: number){
        return this.index(layer_id) == (this.children.length - 1);
    }

    serialize(): SerializedRenderGroup{
        return ({
            "name": this.name,
            "id": this.id,
            "children": this.children,
        });
    }

    static deserialize(serialized : SerializedRenderGroup): RenderGroup{
        let rg = new RenderGroup(serialized.name);
        rg.id = serialized.id;
        for(let id of serialized.children){
            rg.add(id);
        }
        return rg;
    }

}