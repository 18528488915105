//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import {Services} from './Services';
import { Barrier } from '../modules/barrier';
import { UEC, UECArea } from '../modules/tile';

export class Source {
    public instance_name: string;
    public layers: SourceLayer[];
    public category: string;
    public attribution: string;
}

export class SourceLayer{
    public name: string;
    public long_name: string;
    public unit: string;
    public layer_type: string;
    public timesteps: number[];
    public timerange: [number, number];
    public zsteps: number[];
    public zrange: [number, number];
    public datarange: [number, number];
    public max_zoom_level: number;
    public extent: UECArea;
    public default_overlay: string;

    get_time_limits(): [number, number] {
        return this.timerange
    }
}


export class SourceLayerInfo{
    public instance_name: string;
    public layer_name: string;
    public layer: SourceLayer;
    public source: Source;

    constructor(source: Source, layer: SourceLayer){
        this.instance_name = source.instance_name;
        this.layer_name = layer.name;
        this.layer = layer;
        this.source = source;
    }

    resolve_time(start: number, end: number): [number, number]{
        if(!this.layer.timerange || !this.layer.timesteps)return [0, 0];
        if(end < this.layer.timerange[0])[0, 0]
        if(start > this.layer.timerange[1])return[this.layer.timesteps.length - 1, this.layer.timesteps.length - 1];
        let starti = this.layer.timesteps.findIndex(v => v >= start);
        if(starti == -1){
            return [this.layer.timesteps.length - 1, this.layer.timesteps.length - 1];
        }else{
            let endi = this.layer.timesteps.findIndex(v => v >= end);
            if(endi == -1){
                endi = this.layer.timesteps.length - 1;
            }
            if(starti > 0)starti -= 1;
            return [starti, endi];
        }
    }

    resolve_height(h: number): number {
        if(!this.layer.zrange || !this.layer.zsteps)return 0;
        if(h < this.layer.zrange[0])return 0;
        if(h > this.layer.zrange[1])return this.layer.zsteps.length - 1;
        let zi = this.layer.zsteps.findIndex(v => v >= h);
        if(zi == -1) return this.layer.zsteps.length - 1;
        return zi;
    }

    getPath(): string {
        return this.instance_name + "/" + this.layer_name;
    }

    public static pathForSourceAndLayer(source: Source, layer: SourceLayer){
        return source.instance_name + "/" + layer.layer_type;
    }
}

class SourceStatus{
    name: string;
    status: "Loading" | "Loaded" | {Error: string};

    static fromJSONObject(jobj: any): SourceStatus{
        let status = new SourceStatus();
        status.name = jobj[0];
        let status_entry = jobj[1];
        if(status_entry){
            if(status_entry == "Loaded"){
                status.status = "Loaded";
            }else if(status_entry == "Loading"){
                status.status = "Loading";
            }else if(status_entry["Error"]){
                status.status = {Error: status_entry["Error"]}
            }else{
                status.status = {Error: "Received incompatible status type " + JSON.stringify(status_entry)};
            }
        }else{
            status.status = {Error: "No status received for source"};
        }
        return status;
    }
}

export class SourceInfoService{
    private status_entries: Map<string, SourceStatus> = new Map();
    private everything_loaded: boolean = false;

    private source_layer_infos: SourceLayerInfo[] = [];
    private source_list: Source[] = [];
    public source_barrier: Barrier;

    private loading_interval_handle = null;

    constructor(){
        this.source_barrier = new Barrier();
        //Load the source list just now
        setTimeout(() => this.refresh_status_entries().then(() => {}),50);
        //And every 10 seconds until everything is loaded
        this.loading_interval_handle = setInterval(() => {
            this.refresh_status_entries().then(() => {});
            if(this.everythingLoaded()){
                clearInterval(this.loading_interval_handle);
            }
        }, 10 * 1000);
    }

    everythingLoaded(): boolean{
        return this.everything_loaded;
    }

    async refresh_status_entries(){
        let statuslist: any[] = await fetch(Services.InitializationService.SERVER_URL + "/" + Services.InitializationService.STATUSLIST_URL).then(r => r.json());
        let everything_loaded = true;
        for(let statusproto of statuslist){
            let status = SourceStatus.fromJSONObject(statusproto);
            if(this.status_entries.has(status.name)){
                let old_status = this.status_entries.get(status.name);
                if(old_status.status == "Loading" && status.status == "Loaded"){
                    await this.load_source_info(status.name);
                }
            }else{
                if(status.status == "Loaded"){
                    await this.load_source_info(status.name);
                }
            }
            this.status_entries.set(status.name, status);
            if(status.status == "Loading"){
                everything_loaded = false;
            }
        };
        this.everything_loaded = everything_loaded;
    }

    async load_source_info(sourcename: string){
        let source_info_proto = await fetch(Services.InitializationService.SERVER_URL + "/" + Services.InitializationService.SOURCEINFO_URL + "/" + sourcename).then(r => r.json());
        let source: Source = Object.assign(new Source(), source_info_proto);
        source.layers = source.layers.map((layer: SourceLayer) => {
            layer = Object.assign(new SourceLayer(), layer);
            if(layer.extent){
                layer.extent.position = UEC.from_ueclike(layer.extent.position);
                layer.extent.extent = UEC.from_ueclike(layer.extent.extent);
                layer.extent = new UECArea(layer.extent.position, layer.extent.extent);
            }
            return layer;
        });
        source.layers.sort((a,b) => a.name.localeCompare(b.name));
        this.source_list.push(source);
        this.source_list.sort((a,b) => a.instance_name.localeCompare(b.instance_name));
        for(let layer of source.layers){
            this.source_layer_infos.push(new SourceLayerInfo(source, layer));
        }
        this.source_layer_infos.sort((a,b) => a.getPath().localeCompare(b.getPath()));
        this.source_barrier.resolve();
    }

    getBarrier(): Barrier{
        return this.source_barrier;
    }

    get_source_layer_info_by_path(path: string){
        return this.source_layer_infos.find(sli => sli.getPath() == path);
    }

    get_all_source_layer_infos(){
        return this.source_layer_infos;
    }

}