//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { RenderLayer } from "../modules/RenderLayer";
import { UEC, UECArea } from "../modules/tile";
import { GLBarrier, ServiceBarrier, Services } from "./Services";
import { DialogBox } from "./DialogBoxService";
import { InteractionState, InteractionStateChangedEvent } from "./InteractionService";
import { RenderSource } from "../modules/rendersources/RenderSource";
import { Mat4 } from "../modules/vecmat";
import { CompositionFilter } from "../modules/renderfilters/RenderFilter";

export class SelectionService extends EventTarget{
    corner1: UEC;
    corner2: UEC;
    selecting: boolean;
    renderLayer: SelectionRenderLayer;

    constructor(){
        super();
        ServiceBarrier.wait().then(() => {
            this.selecting = false;
            Services.InteractionService.addEventListener("InteractionStateChanged", (e: InteractionStateChangedEvent) => {
                if(e.state == InteractionState.Selection)
                    Services.DialogBoxService.try_insert("Selection", new DialogBox("SelectionComponent", 32,64, 32, 32, "Selection"));
                else
                    Services.DialogBoxService.remove("Selection", e.state);
            });
        });
        GLBarrier.wait().then(() => {
            this.renderLayer = new SelectionRenderLayer();
        });
        this.selectNothing();
    }

    selectAll(){
        this.corner1 = new UEC(0,0);
        this.corner2 = new UEC(1,1);
        this.selecting = false;
        this.raiseSelectionChanged()
    }
    selectNothing(){
        this.corner1 = new UEC(0,0);
        this.corner2 = new UEC(0,0);
        this.selecting = false;
        this.raiseSelectionChanged();
    }

    startRectangleSelection(){
        if(Services.LayerMetadataService.isUpToDate){
            this.selecting = true;
            this.corner1 = Services.LayerMetadataService.coord;
        }else{
            this.selecting = false;
        }
    }

    updateRectangleSelection(){
        if(this.selecting){
            this.corner2 = Services.LayerMetadataService.coord;
            this.raiseSelectionChanged();
        }
    }

    getSelectedRectangle(): UECArea{
        return UECArea.fromPoints([
            new UEC(
                Math.min(this.corner1.x, this.corner2.x),
                Math.min(this.corner1.y, this.corner2.y)
            ),
            new UEC(
                Math.max(this.corner1.x, this.corner2.x),
                Math.max(this.corner1.y, this.corner2.y)
            )]
        );
    }

    raiseSelectionChanged(){
        this.dispatchEvent(new SelectionChangedEvent(this.getSelectedRectangle()));
    }

}

class SelectionRenderLayer extends RenderLayer {
    constructor(){
        super();
        this.source = new SelectionRenderSource();
        this.compositionFilter = new CompositionFilter(Services.GLService.Modules.compositing.selection);
    }

    setSelectionExtent(area: UECArea) {

    }
}

class SelectionRenderSource extends RenderSource {
    constructor(){
        super();
            //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.selection;
        this.name = "SelectionRenderSource";
        this.parameters = {};
        this.slots = {};
    }

    execute(context: {[name: string]: WebGLRenderingContext | any; }) {
        let srect = Services.SelectionService.getSelectedRectangle();
        if(srect.extent.x == 0 || srect.extent.y == 0)return;
        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        let buff = Services.GLService.Geometries.selection_box;
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 0, 0);

        let cam_position = Services.PositionService.getCameraPositionFiltered();
        switch (Services.PositionService.projection_mode) {
            case "EQUIRECT": {
                if(cam_position.Longitude < -90 && srect.position.x > 0.5) {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x - 1, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                } else if (cam_position.Longitude > 90 && srect.position.x < 0.5) {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x + 1, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                } else {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                }
                break;
            }
            case "SPHERE": {
                break;
            }
            case "POLAR": {
                break;
            }
        }
        let minmax = Services.RenderLayerService.get_visible_renderlayers().map(r => r.source.getVerticalBoundsWorldSpace()).reduce((minmax, cv) => [Math.min(cv[0], minmax[0]), Math.max(cv[1], minmax[1])], [1, 1]);
        context.gl.uniform1f(this.shader.uniforms["selection_zmin"], minmax[0]);
        context.gl.uniform1f(this.shader.uniforms["selection_height"], minmax[1] - minmax[0]);

        context.gl.uniform2f(this.shader.uniforms["selection_coord"], srect.position.x, srect.position.y);
        context.gl.uniform2f(this.shader.uniforms["selection_size"], srect.extent.x, srect.extent.y);

        context.gl.uniform4f(this.shader.uniforms["selection_color"], 1.0, 0.0, 0.0, 1.0);

        context.gl.drawArrays(buff.mode, buff.start, buff.length);
    }
}

export enum SelectionState{
    Idle,
    SelectingPoints
}

export enum SelectionMode{
    Nothing,
    All,
    Point,
    Rectangle,
    Path
}

export class Selection{
    start: UEC;
    points: UEC[];
    next_point: UEC;
    mode: SelectionMode;
    layer: RenderLayer;
    constructor(mode: SelectionMode){
        this.mode = mode;
        this.start = null;
        this.points = [];
        this.layer = Services.RenderLayerService.getSelectedLayer();
        if(this.mode == SelectionMode.All){
            let extent = Services.RenderLayerService.getSelectedLayer().getExtent();
            this.start = extent.position;
            this.points.push(extent.position.add(extent.extent));
        }
    }

    confirmNextPoint(){
        this.points.push(this.next_point);
    }

    selectionPoints(): UEC[]{
        return [this.start, ...this.points];
    }

    temporarySelectionPoints(): UEC[]{
        return [this.start, ...this.points, this.next_point];
    }

    extent(): UECArea{
        return UECArea.fromPoints(this.selectionPoints());
    }
}

export class SelectionChangedEvent extends Event{
    selection: UECArea;
    constructor(selection: UECArea){
        super("SelectionChanged");
        this.selection = selection;
    }
}