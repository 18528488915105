


































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import {LayersChangedEvent, RenderLayerService, SelectedLayerChangedEvent} from '../services/RenderLayerService'
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';
import { Coord, UEC } from '../modules/tile';
import { DialogBox } from '../services/DialogBoxService';
import { RenderGroup } from '../modules/RenderGroup';

@Component({
    components: {
        SvgIcon,
        ScrollBox,
    },
    props: {
        group: RenderGroup,
        isDefault: Boolean
    },
    name: "layer-group-component"
})
export default class LayerGroupComponent extends Vue{
    expanded: boolean = true;

    mounted(){
        Services.RenderLayerService.addEventListener(
            "LayersChanged",
            (e: LayersChangedEvent) => {
                this.$forceUpdate();
            }
        );
    }

    allowDrop(ev: DragEvent) {
        ev.preventDefault()
    }

    startDrag(ev: DragEvent, id: number) {
        ev.dataTransfer.setData("text/x.plain", id + "");
        ev.dataTransfer.dropEffect = "move";
    }

    
    drop(ev: DragEvent, targetId: number, open: boolean = false) {
        let droppedIdString = ev.dataTransfer.getData("text/x.plain");
        if(!droppedIdString)return;
        let droppedId = parseInt(droppedIdString);

        if(Services.RenderLayerService.isGroup(targetId) && open){

            let source_group: RenderGroup = Services.RenderLayerService.groupForId(droppedId);
            
            if(droppedId == targetId)return;
            if(Services.RenderLayerService.isGroup(droppedId) && Services.RenderLayerService.getGroupById(droppedId).hasChild(targetId))return;
            source_group.remove(droppedId);
            
            let targetGroup: RenderGroup = Services.RenderLayerService.getGroupById(targetId);
            targetGroup.add(droppedId);

        }else{

            let source_group: RenderGroup = Services.RenderLayerService.groupForId(droppedId);
            let targetGroup: RenderGroup = Services.RenderLayerService.groupForId(targetId);
            if(droppedId == targetGroup.id)return;
            if(Services.RenderLayerService.isGroup(droppedId) && Services.RenderLayerService.getGroupById(droppedId).hasChild(targetGroup.id))return;
            source_group.remove(droppedId);

            let target_index = targetGroup.children.indexOf(targetId);

            // @ts-ignore 
            let el = ev.composedPath().find(el => el.classList.contains("drop-bounds"));
            if(el){
                // @ts-ignore 
                let rect = el.getBoundingClientRect();
                let yrel = ev.clientY - rect.y;
                console.log("rel pos: ", yrel);
                if(yrel > rect.height / 2) target_index += 1;
            }

            targetGroup.children.splice(target_index, 0, droppedId);
        }
        Services.RenderLayerService.raiseLayersChanged()
        ev.preventDefault()
    }

    isGroup(id: number): Boolean {
        return Services.RenderLayerService.isGroup(id)
    }

    isLayer(id: number): Boolean {
        return Services.RenderLayerService.isLayer(id)
    }

    getGroup(id: number): RenderGroup {
        return Services.RenderLayerService.groups.get(id)
    }

    getLayer(id: number): RenderLayer {
        return Services.RenderLayerService.layers.get(id)
    }

    isSelected(id: number): Boolean {
        return Services.RenderLayerService.selected_layer_id == id || Services.RenderLayerService.selected_group.id == id
    }

    zoomToLayer(layer: RenderLayer | RenderGroup){

        //BUG: when displacement is larger than data, will likely bound on displacement and not on data
        let extent = layer.getExtent();
        let center = new UEC(extent.position.x + (0.5 * extent.extent.x), extent.position.y + (0.5 * extent.extent.y));
        let coord = Coord.from_UEC(center);
        let position = Services.PositionService.getCameraPosition();
        position.Latitude = coord.lat;
        position.Longitude = coord.lon;
        position.Elevation = 45;
        position.Azimuth = 315;
        position.Distance = Math.max(extent.extent.x * 2 * Math.PI, extent.extent.y * Math.PI); //Works only on 45° fov
        if(position.Distance == 0){
            position.Distance = 0.1;
        }
        let zbounds = layer.getVerticalBoundsNative()
        if(zbounds)
            position.VerticalPosition = (zbounds[0] + zbounds[1]) / 2;
        Services.PositionService.setCameraPosition(position);
    }

    hideExtent(){
        Services.ExtentVisualizationService.hideExtent();
    }

    showExtent(layer: RenderGroup){
        Services.ExtentVisualizationService.showExtent(layer.getExtent(), layer.getVerticalBoundsWorldspace());
    }

    selectLayer(layer: RenderLayer){
        if(Services.RenderLayerService.getSelectedLayer() != layer){
            Services.RenderLayerService.selectLayer(layer);
            Services.AdaptivePerformanceService.RequestRerender();
        }
    }

    layerSettings(layer: RenderLayer){
        this.selectLayer(layer);
        Services.DialogBoxService.try_insert("layer-settings", new DialogBox("LayerComponent", window.innerWidth / 2, window.innerHeight / 2, 32, 32, "Layer Settings", "translate(-50%, -50%)"))
    }

    layerDelete(layer: RenderLayer){
        Services.RenderLayerService.deleteLayer(layer);
        Services.AdaptivePerformanceService.RequestRerender();
    }

    groupDelete(e: MouseEvent, group: RenderGroup){
        if(e.ctrlKey){
            Services.RenderLayerService.deleteGroup(group, true);
        } else {
            Services.RenderLayerService.deleteGroup(group, false);
        }
    }

    createGroup(){
        let newGroup = new RenderGroup("Unnamed Group");
        Services.RenderLayerService.addGroup(newGroup, this.$props.group.id);
    }

    createLayer(){
        Services.RenderLayerService.selectGroup(this.$props.group);
        Services.DialogBoxService.try_insert("New Layer", DialogBox.centered("TypeSelectionDialog", "Select Layer Type"));
    }
    
}
