import { Parameter } from "@/modules/Parameter";
import { glenv } from "./RenderService";
import { ServiceBarrier, Services } from "./Services";

export class Frustum{
    public name: string;
    public fov: {
        "up": number;
        "left": number;
        "right": number;
        "down": number;
    };
    public orientation: {
        "yaw": number,
        "pitch": number,
        "roll": number
    }
}

type ProjectorConfiguration = {
    "id": string,
    "frustum": {
        "orientation": {
            "yaw": number,
            "pitch": number,
            "roll": number
        },
        "fov": {
            "right": number,
            "left": number,
            "up": number,
            "down": number
        }
    },
    "warp": {
        "size": {
            "width": number,
            "height": number,
            "channels": number
        },
        "interpolation": string
    },
    "blend": {
        "size": {
            "width": number,
            "height": number,
            "channels": number
        },
        "blend_gamma_embedded": number
    }
};

export class ProjectorConfigurationService{
    private configurations: Map<string, ProjectorConfiguration> = new Map();
    private frusta: Map<string, Frustum> = new Map();
    private selectedId: string;
    private blendMask: WebGLTexture;
    private warpTexture: WebGLTexture;
    private gl: glenv;

    private null_blend: WebGLTexture;
    private null_warp: WebGLTexture;

    private rot_orient_str: string = "PRPPNH";

    //frusta

    //blends

    //warps

    //ids

    constructor(gl: glenv){
        this.gl = gl;
        ServiceBarrier.wait().then(() => {
            let region_param = new Parameter("DisplayRegion", null, "select", true);
            Services.SettingsService.initializeSetting(region_param);
        });
        (async () => {
            //1. Load data from server
            let configurations: ProjectorConfiguration[] = await fetch("projector_configurations").then(r => r.json());
            for(let c of configurations){
                this.configurations.set(c.id, c);
                let f = new Frustum();
                f.name = c.id;
                f.fov = c.frustum.fov;
                f.orientation = c.frustum.orientation;
                this.frusta.set(c.id, f);
            }
            let display_region_param = Services.SettingsService.getSetting("DisplayRegion");
            display_region_param.options = [... this.configurations.keys()];
            if(display_region_param.options.indexOf(display_region_param.value) != -1){
                this.setSelectedId(display_region_param.value);
            }
            Services.RenderService.forceDomeModeUpdate();
        })();
        this.createNullTextures();
    }

    public getRotationOrderString(): string {
        return this.rot_orient_str;
    }
    public setRotationOrderString(s: string){
        if(!s)return;
        this.rot_orient_str = s;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    createNullTextures(){
        let gl = this.gl.gl;
        this.null_blend = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.null_blend);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.FLOAT, new Float32Array([1.0, 1.0, 1.0, 1.0]));
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);

        this.null_warp = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.null_blend);
        this.warpTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.warpTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, 2,2, 0, gl.RGB, gl.FLOAT, new Float32Array([-1,-1,0,  1,-1,0,  -1,1,0,  1,1,0]));
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }

    setSelectedId(id: string){
        if(this.selectedId != id){
            this.selectedId = id;
            let selectedConfig = this.configurations.get(id);
            let gl = this.gl.gl;
            (async () => {
                let blend_promise;
                let warp_promise;
                if(selectedConfig.blend){
                    blend_promise = fetch(`projector_blend_data/${encodeURIComponent(id)}`).then(r => r.arrayBuffer());
                }
                if(selectedConfig.warp){
                    warp_promise = fetch(`projector_warp_data/${encodeURIComponent(id)}`).then(r => r.arrayBuffer());
                }
                
                if(selectedConfig.warp){
                    let warp_buff = await warp_promise;
                    if(this.warpTexture)gl.deleteTexture(this.warpTexture);
                    this.warpTexture = gl.createTexture();
                    gl.bindTexture(gl.TEXTURE_2D, this.warpTexture);
                    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, selectedConfig.warp.size.width, selectedConfig.warp.size.height, 0, gl.RGB, gl.FLOAT, new Float32Array(warp_buff));
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                    gl.bindTexture(gl.TEXTURE_2D, null);
                }else{
                    if(this.warpTexture)gl.deleteTexture(this.warpTexture);
                    this.warpTexture = null;
                }
                
                if(selectedConfig.blend){
                    let blend_buff = await blend_promise;
                    if(this.blendMask)gl.deleteTexture(this.blendMask);
                    this.blendMask = gl.createTexture();
                    gl.bindTexture(gl.TEXTURE_2D, this.blendMask);
                    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, selectedConfig.warp.size.width, selectedConfig.warp.size.height, 0, gl.RGBA, gl.FLOAT, new Float32Array(blend_buff));
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                    gl.bindTexture(gl.TEXTURE_2D, null);
                }else{
                    if(this.blendMask)gl.deleteTexture(this.blendMask);
                    this.blendMask = null;
                }

                Services.RenderService.forceDomeModeUpdate();              
            })();
        }
    }

    getSelectedId(): string{
        return this.selectedId;
    }

    getConfigIds(): string[]{
        return  [...this.configurations.keys()];
    }

    getFrustumForId(id: string): Frustum{
        return this.frusta.get(id);
    }

    getBlendMask(): WebGLTexture{
        return this.blendMask || this.null_blend;
    }

    getBlendGamma(): number {
        return this.configurations.get(this.selectedId)?.blend?.blend_gamma_embedded || 1.0;
    }

    getWarpTexture(): WebGLTexture{
        return this.warpTexture || this.null_warp;
    }

    static genRotationOrders(): string[]{
        let base_orders = [
            "RPH",
            "PRH",
            "RHP",
            "HRP",
            "HPR",
            "PHR"
        ];
        let r = base_orders.flatMap(x => {
            return [0, 1, 2, 3, 4, 5, 6, 7].map(y => {
                return x.split("").map((z, i) => {
                    return y & (1 << i) ? "P" + z : "N" + z;
                }).join("");
            });
        });
        console.log(r);
        return r;
    }
}