//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { ServiceBarrier, Services } from "./Services";
import { CameraPositionMessage, SynchronizationMessageEvent, TimeRangeMessage } from "./SynchronizationService";
import {SynchronizationMessage} from "./SynchronizationService";
import { FormattingService } from "./FormattingService";
import { TarBall } from "@/modules/tar";

export class RecordingMessage{
    message: SynchronizationMessage;
    timeOffset: number;
    constructor(message: SynchronizationMessage, timeOffset: number){
        this.message = message;
        this.timeOffset = timeOffset;
    }
}

export class PlaybackStateEvent extends Event{
    playback: boolean = false;
    event_number: number = 0;
    constructor(state: boolean, event_number: number){
        super("PlaybackState");
        this.playback = state;
        this.event_number = event_number;
    }
}

export class RecordingStateEvent extends Event{
    recording: boolean = false;
    event_number: number = 0;
    constructor(state: boolean, event_number: number){
        super("RecordingState");
        this.recording = state;
        this.event_number = event_number;
    }
}

export class RecordingService extends EventTarget{
    private first_event_index: number = 0;
    public isRecording: boolean = false;
    public isPlayingBack: boolean = false;
    public recordingActions: RecordingMessage[] = [];
    private recordingStartTime: number = 0;
    private playbackStartTime: number = 0;
    private playbackOffsetTime: number = 0;
    private callback_id = null;

    private frameArray: (Blob | ArrayBuffer)[] = null;
    private render_frame_number: number = 0;

    public startRecording(){
        this.stopPlayback();
        this.recordingActions = [];
        this.recordingStartTime = Services.FlexibleTimeBaseService.now();
        this.isRecording = true;
        this.savePositionAndTime();
        this.dispatchEvent(new RecordingStateEvent(true, 0));
        console.log("Started recording");
    }

    private savePositionAndTime(){
        let pos = Services.PositionService.getCameraPositionFiltered();
        let message: CameraPositionMessage = {
            type: "CameraPosition",
            data: {
                position: pos
            }
        };
        this.handleSynchronizationMessage(message);
        
        let time = Services.TimeService.getCurrentTimeRange();
        if(!!time && !!time[0] && !!time[1]){
            let message2: TimeRangeMessage = {
                type: "TimeRange",
                data: {
                    min: time[0],
                    max: time[1]
                }
            };
            this.handleSynchronizationMessage(message2);
        }
    }

    public stopRecording(){
        if(!this.isRecording) return;
        this.savePositionAndTime();
        this.isRecording = false;
        this.dispatchEvent(new RecordingStateEvent(false, this.recordingActions.length));
        console.log("Stopped recording after " , this.recordingActions.length, " actions");
    }

    public startPlayback(with_frame_capture: boolean = false){
        if(this.recordingActions.length == 0) return;
        if(with_frame_capture){
            this.frameArray = [];
            console.log(this.frameArray);
            Services.FlexibleTimeBaseService.set_lock_state(true);
            Services.TileCacheService.allow_preload = false;
            Services.TileCacheService.maximum_loading = 32;
        }
        this.stopRecording();
        this.isPlayingBack = true;
        this.first_event_index = 0;
        this.playbackStartTime = Services.FlexibleTimeBaseService.now();
        this.dispatchEvent(new PlaybackStateEvent(true, this.first_event_index));
        let first_time_event = this.recordingActions.find(a => a.message.type = "TimeRange");
        if(first_time_event){
            this.playback_state_helper.time_warp_state = first_time_event.message.data;
            this.playback_state_helper.time_warp_target = first_time_event.message.data;
            this.playback_state_helper.time_warp_target_time = first_time_event.timeOffset;
            this.playback_state_helper.time_warp_start_time = 0;
        }
        Services.AdaptivePerformanceService.RequestRerender();
    }

    private playback_state_helper = {
        time_warp_state: null,
        time_warp_start_time: null,
        time_warp_target: null,
        time_warp_target_time: null
    };

    private lerp(xmin, xmax, ymin, ymax, x){
        let status = (x - xmin) / (xmax - xmin);
        return ymin + (status * (ymax - ymin));
    }

    public handlePlayback(){
        if(!this.isPlayingBack)return;
        let tdiff = Services.FlexibleTimeBaseService.now() - this.playbackStartTime;

        //handle non-time events
        let last_event_index = this.recordingActions.length;
        for(var i = this.first_event_index; i < this.recordingActions.length; i++){
            if(this.recordingActions[i].timeOffset > tdiff){
                last_event_index = i;
                break;
            }
        }
        if(last_event_index > this.first_event_index){
            this.dispatchEvent(new PlaybackStateEvent(true, last_event_index));
        }
        let this_frame_events = this.recordingActions.slice(this.first_event_index, last_event_index).filter((x) => x.message.type != "TimeRange");

        this.first_event_index = last_event_index;
        let handledMessageTypes = new Set();
        for(var i = this_frame_events.length - 1; i >= 0; i--){
            if(!handledMessageTypes.has(this_frame_events[i].message.type)){
                handledMessageTypes.add(this_frame_events[i].message.type);
                this.broadcastSynchronizationMessage(this_frame_events[i].message);
            }
        }

        //handle time events
        let first_time_range = null;
        let last_time_range = null;
        for(var i = 0; i < this.recordingActions.length; i++){
            let x = this.recordingActions[i];
            if(x.timeOffset <= tdiff && x.message.type == "TimeRange"){
                last_time_range = x;
            }
            if(x.timeOffset > tdiff)break;
        }
        for(var i = this.recordingActions.length - 1; i >= 0; i--){
            let x = this.recordingActions[i];
            if(x.timeOffset >= tdiff && x.message.type == "TimeRange"){
                last_time_range = x;
            }
            if(x.timeOffset < tdiff)break;
        }
        if(first_time_range && last_time_range){
            let xmin = first_time_range.timeOffset;
            let xmax = last_time_range.timeOffset;
            this.broadcastSynchronizationMessage({
                type: "TimeRange",
                data: {
                    min: this.lerp(xmin, xmax, first_time_range.message.data.min, last_time_range.message.data.min, tdiff),
                    max: this.lerp(xmin, xmax, first_time_range.message.data.max, last_time_range.message.data.max, tdiff)
                }
            });
        } else if(first_time_range){
            this.broadcastSynchronizationMessage({
                type: "TimeRange",
                data: {
                    min: first_time_range.message.data.min,
                    max: first_time_range.message.data.max
                }
            });
        } else if(last_time_range){
            this.broadcastSynchronizationMessage({
                type: "TimeRange",
                data: {
                    min: last_time_range.message.data.min,
                    max: last_time_range.message.data.max
                }
            });
        }

        if(this.first_event_index >= this.recordingActions.length){ //Stop playing if we're over the edge
            this.isPlayingBack = false;
            Services.FlexibleTimeBaseService.set_lock_state(false);
            this.saveZipFrames();
            this.dispatchEvent(new PlaybackStateEvent(false, this.first_event_index));
        }

        Services.AdaptivePerformanceService.RequestRerender();
    }

    private saveZipFrames(){
        if(this.frameArray){
                //Append save script
                let scene_name_string = Services.InitializationService.SceneName;
                scene_name_string = scene_name_string.replaceAll(/[^a-zA-Z0-9]/ig, "_");
                let vwidth = Services.RenderService.width;
                let vheight = Services.RenderService.height;
                if(vwidth % 2)vwidth -= 1;
                if(vheight % 2)vheight -= 1;
                let encode_string_bash = new TextEncoder().encode(`ffmpeg -i %05d.jpeg -r 30 -c:v libx264 -crf 18 -pix_fmt yuv420p -s ${vwidth | 0}x${vheight | 0} ${scene_name_string}.mp4\n`);
                this.frameArray.push(TarBall.gen_header("create_mp4_video.sh", encode_string_bash.byteLength));
                this.frameArray.push(encode_string_bash);
                if(encode_string_bash.byteLength % 512){
                    this.frameArray.push(new ArrayBuffer(512 - encode_string_bash.byteLength % 512));
                }
                let encode_string_cmd = new TextEncoder().encode(`ffmpeg.exe -i %%05d.jpeg -r 30 -c:v libx264 -crf 18 -pix_fmt yuv420p -s ${vwidth | 0 }x${vheight | 0} ${scene_name_string}.mp4\r\n`)
                this.frameArray.push(TarBall.gen_header("create_mp4_video.bat", encode_string_cmd.byteLength));
                this.frameArray.push(encode_string_cmd);
                if(encode_string_cmd.byteLength % 512){
                    this.frameArray.push(new ArrayBuffer(512 - encode_string_cmd.byteLength % 512));
                }
                this.frameArray.push(new ArrayBuffer(512 * 2));
                //Save blob
                let b = new Blob(this.frameArray);
                console.log(`Created blob for ${this.render_frame_number} frames.`);
                let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
                a_elem.setAttribute("download", `${scene_name_string}.tar`);
                a_elem.setAttribute("href", URL.createObjectURL(b));
                a_elem.click();
        }
        this.frameArray = null;
        this.render_frame_number = 0;
    }

    public stopPlayback(){
        if(this.callback_id) clearTimeout(this.callback_id);
        this.callback_id = null;
        if(!this.isPlayingBack) return;
        this.isPlayingBack = false;
        Services.FlexibleTimeBaseService.set_lock_state(false);
        this.saveZipFrames();
        this.dispatchEvent(new PlaybackStateEvent(false, this.first_event_index));
    }

    public goEndPlayback(){
        if(this.recordingActions.length == 0) return;
        this.stopPlayback();
        this.first_event_index = this.recordingActions.length;
        this.isPlayingBack = false;
        this.broadcastSynchronizationMessage(this.recordingActions[this.recordingActions.length - 1].message);
        this.dispatchEvent(new PlaybackStateEvent(false, this.first_event_index));
    }

    public goStartPlayback(){
        if(this.recordingActions.length == 0) return;
        this.stopPlayback();
        this.first_event_index = 0;
        this.isPlayingBack = false;
        this.broadcastSynchronizationMessage(this.recordingActions[0].message);
        this.dispatchEvent(new PlaybackStateEvent(false, 0));
    }

    constructor(){
        super();
        ServiceBarrier.wait().then(() => {
            Services.SynchronizationService.addEventListener("SynchronizationMessage", (e: SynchronizationMessageEvent) => {
                this.handleSynchronizationMessage(e.message);
            });
        });
    }

    private handleSynchronizationMessage(m: SynchronizationMessage){
        if(this.isRecording){
            if(this.recordingActions.length == 0){
                this.recordingStartTime = Services.FlexibleTimeBaseService.now();
            }
            let time = Services.FlexibleTimeBaseService.now() - this.recordingStartTime;
            this.recordingActions.push(new RecordingMessage(m, time));
            this.dispatchEvent(new RecordingStateEvent(true, this.recordingActions.length));
        }
    }

    public acceptsFrames(): boolean {
        return !(this.frameArray === null);
    }

    public submitFrameBlob(b: Blob) {
        if(!this.frameArray)return;
        this.frameArray.push(TarBall.gen_header(FormattingService.leftpad("" + this.render_frame_number , "0", 5) + ".jpeg", b.size));
        this.frameArray.push(b);
        if(b.size % 512){
            this.frameArray.push(new ArrayBuffer(512 - (b.size % 512)));
        }
        this.render_frame_number += 1;
    }

    public broadcastSynchronizationMessage(m: SynchronizationMessage){
        Services.SynchronizationService.handleIncomingMessage(m, Services.SettingsService.getValueOrDefault("Synchronized",false)); //just pass it through for the moment
    }

}