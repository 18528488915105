//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { ArrayData } from "../../services/TileCacheService";
import { GeoPoint, UEC, UECArea } from "../tile";

export class ComputedLineRenderSource extends RenderSource {

    public points: GeoPoint[] = [];

    private dataBuffer: WebGLBuffer;
    private dataBufferNeedsUpdate: boolean;
    private offsetPoint: UEC;

    constructor(points: GeoPoint[]) {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.lines;
        this.name = "LineRenderSource";
        this.parameters = {
            "displacement_scale": Services.SettingsService.getSetting("Exaggeration"),
            "displacement_offset": new Parameter("Vertical Offset", 0, "number", true),
            "line_width": new Parameter("Line Width", 1, "number", true),
        };
        this.parameters["displacement_offset"].shader_name = "displacement_offset";

        this.slots = {};

        this.points = points;
        this.updateDataBuffer();
    }

    public lineLength(){
        if(this.points.length < 2){
            return 0;
        }else{
            let total_length = 0;
            let first_point = this.points[0];
            for(let i = 1; i < this.points.length; i++){
                let point = this.points[i];
                total_length += point.haversine_distance_with_heights(first_point);
                first_point = point;
            }
            return total_length;
        }
    }

    public updateDataBuffer(){
        this.dataBufferNeedsUpdate = true;
    }

    private actuallyUpdateDataBuffer(){
        let gl: WebGLRenderingContext = Services.GLService.getContext().gl;
        if(!!this.dataBuffer){
            gl.deleteBuffer(this.dataBuffer);
        }
        this.points = this.points.filter(p => !!p);
        console.log("Creating new line of ", this.points.length, " points");
        this.dataBuffer = gl.createBuffer();
        this.offsetPoint = this.getExtent().position;
        let data = new Float32Array(
            this.points.flatMap(p => [
                p.position.x - this.offsetPoint.x,
                p.position.y - this.offsetPoint.y,
                p.height,
                p.value,
                p.time
            ]));
        gl.bindBuffer(gl.ARRAY_BUFFER, this.dataBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);
    }

    private getHeightRange(): [number, number]{
        let min_height = Infinity;
        let max_height = -Infinity;
        for(let point of this.points){
            min_height = Math.min(point.height);
            max_height = Math.max(point.height);
        }
        return [min_height, max_height];
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        let [min, max] = this.getHeightRange();
        let h1_scaled = this.applyScaling(min);
        let h2_scaled = this.applyScaling(max);
        return [
            Math.min(h1_scaled, h2_scaled),
            Math.max(h1_scaled, h2_scaled)
        ];
    }

    getVerticalBoundsNative(): [number, number] {
        let [min, max] = this.getHeightRange();
        let h1_scaled = this.applyOffset(min);
        let h2_scaled = this.applyOffset(max);
        return [
            Math.min(h1_scaled, h2_scaled),
            Math.max(h1_scaled, h2_scaled)
        ];
    }

    getExtent(): UECArea {
        return UECArea.fromPoints(this.points.map(p => p.position));
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        if(this.points.length < 2) return;

        if(this.dataBufferNeedsUpdate){
            this.actuallyUpdateDataBuffer();
            this.dataBufferNeedsUpdate = false;
        }

        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);

        context.gl.uniform1f(this.shader.uniforms["time_min"], 0);
        context.gl.uniform1f(this.shader.uniforms["time_max"], 0);


        let line_geo = Services.GLService.Geometries.lines;
        context.gl.enableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, line_geo.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["geometry_position"], 3, context.gl.FLOAT, false, 0, 0);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["geometry_position"], 0);

        let screensize = Services.PositionService.getScreenDimensions();
        context.gl.uniform2f(this.shader.uniforms["point_size"], this.parameters["line_width"].value / screensize.width, this.parameters["line_width"].value / screensize.height);

        switch (Services.PositionService.projection_mode) {
            case "EQUIRECT": {
                context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(1, 0, 0, this.offsetPoint.x, 0, 1, 0, this.offsetPoint.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                break;
            }
            case "SPHERE": {
                break;
            }
            case "POLAR": {
                break;
            }
        }
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 10 * 4, 0);
    
        context.gl.enableVertexAttribArray(this.shader.attributes["value"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["value"], 1, context.gl.FLOAT, false, 10 * 4, 3 * 4);

        context.gl.enableVertexAttribArray(this.shader.attributes["time"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["time"], 1, context.gl.FLOAT, false, 10 * 4, 4 * 4);

        context.gl.enableVertexAttribArray(this.shader.attributes["position2"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position2"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["position2"], 3, context.gl.FLOAT, false, 10 * 4, 5 * 4);
    
        context.gl.enableVertexAttribArray(this.shader.attributes["value2"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value2"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["value2"], 1, context.gl.FLOAT, false, 10 * 4, 8 * 4);

        context.gl.enableVertexAttribArray(this.shader.attributes["time2"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.dataBuffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time2"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["time2"], 1, context.gl.FLOAT, false, 10 * 4, 9 * 4);

        context.gl.uniform2f(this.shader.uniforms["reference_position"], this.offsetPoint.x, this.offsetPoint.y);

        context.ANGLE_instanced_arrays.drawArraysInstancedANGLE(context.gl.TRIANGLE_STRIP, 0, line_geo.length, this.points.length / 2);
         

        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.disableVertexAttribArray(this.shader.attributes["position2"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position2"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value2"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value2"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time2"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time2"], 0);
    }
}
